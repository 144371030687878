import { NoteType, NoteResponseType, FieldTypeEnum } from "./types";
import { GetCurrentNoteSchemaResponse } from "../useGetCurrentNoteSchema";
import { groupBy } from "remeda";
import {
  TimelineAppointment,
  TimelineAppointmentEdge,
} from "../useGetUpcomingAppointments";
import { DrawerState } from "../state";
import { NudgeObject, NudgeSchemaTrigger } from "@grow-therapy-team/sprout-ui";
import { EventTypeEnum } from "../../segment/types";
import { logger } from "../../datadog";

export function formatNotes(
  notes: NoteResponseType,
  currentNoteSchema?: GetCurrentNoteSchemaResponse,
): Record<string, NoteType[]> {
  if (!currentNoteSchema || !notes) return {};
  const formattedNotes: Record<string, NoteType[]> = {};

  currentNoteSchema.currentNoteSchema.sections.forEach((section) => {
    formattedNotes[section.label] = [];

    section.fields.forEach((field) => {
      let answer: string | string[] | boolean = "";
      if (field.fieldType === FieldTypeEnum.CHECKBOX) {
        answer =
          field.name === "risk_issues" && notes[field.name] === false
            ? "No suicidal or homicidal ideation suspected or disclosed"
            : notes[field.name];
      } else if (field.fieldType === FieldTypeEnum.MULTISELECT) {
        answer = Array.isArray(notes[field.name]) ? notes[field.name] : [];
      } else {
        answer = notes[field.name] || answer;
      }

      let details: string[] = [];

      // Many questions have supplemental questions that are appended by _details.
      // It's easier to keep track of by storing these details in the same section object rather than treating it like a separate question
      const baseFieldName = field.name.replace(/_details$/, "");
      const isDetailsField = baseFieldName !== field.name;

      if (isDetailsField) {
        const fieldValue = notes[field.name];
        details = Array.isArray(fieldValue)
          ? fieldValue
          : ([fieldValue] as string[]).filter(Boolean);

        const baseFieldFormattedSection = formattedNotes[section.label].find(
          (sectionItem) => sectionItem.name === baseFieldName,
        );

        if (baseFieldFormattedSection) {
          baseFieldFormattedSection.details = details;
        }
      } else {
        const formattedSection: NoteType = {
          question: field.label,
          name: field.name,
          answer: answer,
          margin: field.margin,
          textVariant: field.textVariant,
          fieldType: field.fieldType as FieldTypeEnum,
          details: details,
        };
        formattedNotes[section.label].push(formattedSection);
      }
    });
  });

  return formattedNotes;
}

/** Groups upcoming appointments so that recurring appointments show only the next upcoming entry and sorts them in date order */
export function formatUpcomingAppointments(
  appointments: TimelineAppointmentEdge[] | undefined,
): TimelineAppointment[] {
  const groupedAppointments = appointments
    ? groupBy(
        appointments,
        (appointment) =>
          appointment?.node?.recurringAppointmentGroupingId ??
          appointment?.node?.id,
      )
    : {};
  const upcomingAppointments = Object.values(groupedAppointments).map(
    (appointmentGroup) => appointmentGroup[0].node,
  );
  return upcomingAppointments.sort((a, b) => {
    return new Date(a.timeStart).getTime() - new Date(b.timeStart).getTime();
  });
}

/**
 * Creates a mapping of `NudgeSchemaTrigger` to corresponding click handler functions.
 *
 * Each handler is responsible for performing an action based on the trigger type.
 * For the `CLINICAL_RISK_REVIEW` trigger, it sets the drawer state to `CSSRS_FORM`.
 * For other triggers, it opens the provider dashboard referral URI in a new tab.
 * The handler also logs a `PROVIDER_NUDGE_CLICKED` event with the associated nudge ID.
 *
 * @param {NudgeObject[]} nudges - An array of nudge objects containing the trigger and ID information.
 * @param {(state: DrawerState) => void} setDrawerState - A function to update the state of the drawer.
 * @param {string} providerDashboardReferralUri - The URI to be opened for psychiatric medication review triggers.
 * @param {(eventType: EventTypeEnum, payload: { nudgeId: number }) => void} userClicked - A function to log events, called with the nudge ID and event type.
 * @returns {Partial<Record<NudgeSchemaTrigger, () => void>>} - A mapping of nudge triggers to their respective click handler functions.
 */
export function createNudgeOnClickHandlers(
  nudges: NudgeObject[],
  setDrawerState: (state: DrawerState) => void,
  providerDashboardReferralUri: string,
  userClicked: (eventType: EventTypeEnum, payload: { nudgeId: number }) => void,
): Partial<Record<NudgeSchemaTrigger, () => void>> {
  const nudgeMap = new Map<NudgeSchemaTrigger, number>(
    nudges.map((nudge) => [nudge.nudgeSchema.trigger, nudge.id]),
  );

  const handleTrigger = (trigger: NudgeSchemaTrigger, nudgeId: number) => {
    switch (trigger) {
      case NudgeSchemaTrigger.CLINICAL_RISK_REVIEW:
        setDrawerState(DrawerState.CSSRS_FORM);
        break;
      case NudgeSchemaTrigger.PSYCHIATRIC_MEDICATION_EVAL_WITHOUT_SUICIDALITY:
        window.open(
          providerDashboardReferralUri,
          "_blank",
          "noopener,noreferrer",
        );
        break;
      default:
        logger.warn("Unhandled nudge trigger", trigger);
    }

    userClicked(EventTypeEnum.PROVIDER_NUDGE_CLICKED, { nudgeId });
  };

  return Object.fromEntries(
    Array.from(nudgeMap.entries()).map(([trigger, nudgeId]) => [
      trigger,
      () => handleTrigger(trigger, nudgeId),
    ]),
  );
}
