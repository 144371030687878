import { Heading, Text, IconButton, Icon } from "@grow-therapy-team/sprout-ui";
import { useSetAtom } from "jotai";
import { drawerStateAtom, DrawerState } from "../state";
import classNames from "classnames";
import { faAngleRight, faCalendar } from "@fortawesome/pro-solid-svg-icons";

export function ClientInformationNotes({
  hasBottomBorder = true,
}: {
  hasBottomBorder?: boolean;
}) {
  const setDrawerState = useSetAtom(drawerStateAtom);

  const noteTitle = "Last session's notes";

  return (
    <div
      className="flex flex-col mt-6"
      data-testid="client-information-section.body.content"
    >
      <Heading variant="md">{noteTitle}</Heading>
      <button
        className={classNames(
          "relative flex w-full items-center space-x-4  py-4 text-start border-neutral-600 hover:bg-neutral-400",
          {
            "border-b": hasBottomBorder,
          },
        )}
        onClick={() => setDrawerState(DrawerState.PROGRESS_NOTES)}
      >
        <div className="flex h-12 max-h-[3rem] w-16 max-w-[4rem] items-center justify-center rounded font-bold">
          <div className="flex-row text-center leading-tight">
            <IconButton
              iconDefinition={faCalendar}
              aria-label="Open Notes"
              className="bg-neutral-400 px-6 py-4"
              use="unstyled"
            />
          </div>
        </div>
        <div className="flex grow justify-between">
          <div>
            <Text>Progress Note</Text>
          </div>
          <div className="flex items-center">
            <Icon icon={faAngleRight} className="mr-2" />
          </div>
        </div>
      </button>
    </div>
  );
}
