import { useCallback, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { CSSRSPromptAlert } from "./CSSRSPromptAlert";
import { useAtomValue, useSetAtom } from "jotai";
import { DrawerState, drawerStateAtom } from "../state";
import { twilioRoomAtom } from "../../twilio";

export function useCSSRSPromptToastCallback() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const twilioRoom = useAtomValue(twilioRoomAtom);
  const toastIdRef = useRef<string | null>(null);

  useEffect(
    function clearToastOnTwilioRoomDisconnect() {
      if (!twilioRoom && toastIdRef.current) {
        toast.remove(toastIdRef.current);
      }
    },
    [twilioRoom],
  );

  return useCallback(
    (patientName: string) => {
      toast.custom(
        ({ id: toastId }) => {
          toastIdRef.current = toastId;
          return (
            <CSSRSPromptAlert
              onClose={() => toast.remove(toastId)}
              patientName={patientName}
              onAdministerCSSRS={() => {
                setDrawerState(DrawerState.CSSRS_FORM);
                toast.remove(toastId);
              }}
              className="bottom-center-toast max-w-4xl"
            />
          );
        },
        {
          duration: Infinity,
          position: "bottom-center",
        },
      );
    },
    [setDrawerState],
  );
}
