import { Text } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";
import { ProviderState, providerStateAtom } from "./state";
import { useAtomValue } from "jotai";

export type ProviderGreetingProps = {
  className?: string;
  providerState: ProviderState;
};

export function ProviderGreeting({
  className,
  providerState,
}: ProviderGreetingProps) {
  return (
    <div
      className={twMerge(
        "flex flex-col gap-y-4 items-center text-center",
        className,
      )}
    >
      {providerState === ProviderState.COMPLETED ? (
        <Text as="h2" variant="xl" className="font-medium text-neutral-100">
          Your session has ended.
        </Text>
      ) : (
        <Text as="h2" variant="xl" className="font-medium text-neutral-100">
          Welcome to your meeting room!
        </Text>
      )}
    </div>
  );
}

export function ProviderGreetingWrapper(
  props: Omit<ProviderGreetingProps, "providerState">,
) {
  const providerState = useAtomValue(providerStateAtom);

  return <ProviderGreeting {...props} providerState={providerState} />;
}
