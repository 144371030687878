import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
} from "../state";
import { useMemo, useState } from "react";
import {
  DrawerBody,
  MICMeasureBundleResponses,
  MeasureBundlesList,
  findMostRecentCompletedPHQBundle,
  shouldPromptForCSSRS,
} from "@grow-therapy-team/sprout-ui";
import { ClientInformationDrawerHeader } from "../client-information/ClientInformationDrawerHeader";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { useGate } from "statsig-react";
import { cssrsMeasuresGate } from "../../statsig/gates";
import { CSSRSPromptAlert } from "./CSSRSPromptAlert";

export function MeasureResponsesDrawer() {
  const { value: cssrsMeasureEnabled } = useGate(cssrsMeasuresGate);
  const selectedClientInfo = useAtomValue(selectedClientInfoAtom);
  const patientShortId = selectedClientInfo?.patientShortId;
  const preferredFirstName =
    selectedClientInfo?.preferredName?.patientFirstName;
  const setDrawerState = useSetAtom(drawerStateAtom);
  const [selectedBundle, setSelectedBundle] = useAtom(selectedBundleAtom);

  const [cssrsAlertIsDismissed, setCssrsAlertIsDismissed] = useState(false);

  const { data } = useGetPatientByShortId(patientShortId);
  const measureBundles = data?.patientChart?.measureBundles;

  const shouldPromptForCssrs = shouldPromptForCSSRS(measureBundles);
  const mostRecentPHQ9bundle = findMostRecentCompletedPHQBundle(
    measureBundles ?? [],
  );

  //only show the alert if the selected bundle is the most recent PHQ9 bundle
  const isMostRecentPHQ9Bundle =
    mostRecentPHQ9bundle?.measureBundleShortId ===
    selectedBundle?.measureBundleShortId;

  const shouldShowCssrsAlert =
    !cssrsAlertIsDismissed &&
    cssrsMeasureEnabled &&
    shouldPromptForCssrs &&
    isMostRecentPHQ9Bundle;

  const onClickBackButtonWrapped = () => {
    if (selectedBundle) {
      setSelectedBundle(undefined);
      return;
    }

    setDrawerState((prev) => {
      if (prev === DrawerState.MEASURE_RESPONSES) {
        return DrawerState.CLIENT_INFORMATION;
      }

      return DrawerState.MEASURE_RESPONSES;
    });
  };

  const onAdministerCSSRS = () => {
    setDrawerState(DrawerState.CSSRS_FORM);
  };

  const drawerTitle = useMemo(() => {
    if (selectedBundle?.sentAt) {
      return `Check-in from ${new Date(
        selectedBundle.sentAt,
      ).toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
        year: "numeric",
      })}`;
    }
    if (preferredFirstName) {
      return `${preferredFirstName}'s Measures`;
    }
    return "Measures";
  }, [selectedBundle, preferredFirstName]);

  if (!data) return;

  return (
    <>
      <ClientInformationDrawerHeader
        drawerTitle={drawerTitle}
        onClickBackButton={onClickBackButtonWrapped}
      />
      <DrawerBody>
        {selectedBundle ? (
          <MICMeasureBundleResponses
            cssrsAlert={
              shouldShowCssrsAlert ? (
                <CSSRSPromptAlert
                  onClose={() => setCssrsAlertIsDismissed(true)}
                  patientName={preferredFirstName ?? ""}
                  onAdministerCSSRS={onAdministerCSSRS}
                />
              ) : undefined
            }
            measureBundle={selectedBundle}
          />
        ) : (
          <MeasureBundlesList
            cssrsMeasureEnabled={cssrsMeasureEnabled}
            measureBundles={data?.patientChart?.measureBundles}
            onClickBundle={(bundle) => {
              setSelectedBundle(bundle);
            }}
          />
        )}
      </DrawerBody>
    </>
  );
}
