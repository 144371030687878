import { Theme } from "../state";
import {
  Navbar,
  PageContainer,
  PageProviders,
  PushDrawerContainer,
  StageContainer,
  useDetectStaleVersionInterval,
} from "../components";
import RebrandBackground from "../assets/provider-bg.svg?react";
import { ProviderAvatarWrapper as ProviderAvatar } from "./ProviderAvatar";
import { SelfThumbnailVideo } from "./SelfThumbnailVideo";
import { ClientsDrawerButtonWrapper as ClientsDrawerButton } from "./clients-drawer";
import { useProviderTwilioRoomListeners } from "./useProviderTwilioRoomListeners";
import { ProviderGreetingWrapper as ProviderGreeting } from "./ProviderGreeting";
import { SchedulePreviewWrapper as SchedulePreview } from "./schedule-preview/SchedulePreview";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  AudioVideoControlsWrapper as AudioVideoControls,
  isScreenShareModeAtom,
  participantCountAtom,
  twilioRoomAtom,
  useHandleDeviceChanges,
  useInitLocalTracks,
} from "../twilio";
import { CompleteSessionButton } from "./CompleteSessionButton";
import { useVisitorPresenceMap } from "./useVisitorPresenceMap";
import { StartNewSessionConfirmationModalWrapper as StartNewSessionConfirmationModal } from "./clients-drawer/StartNewSessionConfirmationModal";
import { useRecordUnaccompaniedProviderInterval } from "./useRecordUnaccompaniedProviderInterval";
import { useNetworkQualityLogging } from "../twilio/useNetworkQualityLogging";
import { nonEssentialElementProps, useSessionFocus } from "../hooks";
import { useInitProviderGlobalDDLoggerContext } from "./useInitProviderGlobalDDLoggerContext";
import { useResetVideoOnMobileNavigation } from "../twilio/useResetVideoOnMobileNavigation";
import { useInitProviderUserTracking } from "./useInitProviderUserTracking";
import { InSessionTimerWrapper as InSessionTimer } from "./InSessionTimer";
import { useMaxDurationWarning } from "./useMaxDurationWarning";
import { SessionGrid } from "../twilio/session-grid/SessionGrid";
import { useInitProviderChatClient } from "./chat";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  providerShortIdAtom,
} from "./state";
import { ChatDrawerButtonWrapper as ChatDrawerButton } from "./chat/ChatDrawerButton";
import { PushDrawerWrapper as PushDrawer } from "./PushDrawer";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { HelpModalWrapper as HelpModal } from "./HelpModal";
import { useShowNotificationInFaviconAndTitle } from "./useUpdateFaviconAndTitle";
import { AdditionalOptionsMenuWrapper as AdditionalOptionsMenu } from "./AdditionalOptionsMenu";
import { TrackingEvents, sendLoggingEvents } from "../events";
import { useInitTwilioSyncClient } from "./sync";
import classNames from "classnames";
import { useUpdateTrackStatsInterval } from "../twilio/stats/useUpdateTrackStatsInterval";
import { ClientInformationButtonWrapper as ClientInformationButton } from "./client-information/ClientInformationButton";
import { useOpenClientInformationCallback } from "./client-information";
import { useGate } from "statsig-react";
import { telehealthTranscriptionGate } from "../statsig/gates";
import { useAnnouncementToast } from "./useAnnouncementToast";
import { useInactiveTabPerformanceOptimization } from "../hooks/useInactiveTabPerformanceOptimization";
import { usePromptFollowUpToast } from "./schedule-appointment/usePromptFollowUpToast";
import { EventTypeEnum } from "../segment/types";
import { useWaitingRoomTrackingEvent } from "../hooks/useWaitingRoomTrackingEvent";
import { useSyncMoodCheckin } from "./measures/useSyncMoodCheckin";
import { RecordingControl } from "./session-recording/RecordingControl";
import { RemoteParticipant } from "./RemoteParticipant";
import { TranscriptionPillWrapper } from "./session-recording/TranscriptionPill";
import toast from "react-hot-toast";
import { AvsCtaPillWrapper as AvsCtaPill } from "./after-visit-summary/AvsCtaPill";
import { usePeriodicallySendParticipantState } from "../twilio/messages/usePeriodicallySendParticipantState";
import { useSendProviderParticipantStateCallback } from "./messages/useSendProviderParticipantStateCallback";
import { deviceIsMobile } from "../utils";

const PUSH_DRAWER_ARIA_CONTROLS_ID = "provider-push-drawer";

function Background() {
  return (
    <RebrandBackground
      className="pointer-events-none bg-neutral-900 absolute w-screen h-screen"
      aria-hidden
    />
  );
}

function InSessionStageEffects() {
  const sendParticipantState = useSendProviderParticipantStateCallback();
  usePeriodicallySendParticipantState(sendParticipantState);
  return null;
}

export const Stage = memo(function Stage() {
  const inSession = !!useAtomValue(participantCountAtom);
  const isScreenShareMode = useAtomValue(isScreenShareModeAtom);
  const { value: isTranscriptionGateEnabled } = useGate(
    telehealthTranscriptionGate,
  );
  const isMobile = deviceIsMobile();
  const thumbnail = useMemo(
    () => (
      <SelfThumbnailVideo
        showExpandVideoToggle={false}
        shouldShowHideSelf={false}
        showSetPositionMenu={false}
        className={classNames({
          "flex-1 max-w-[15rem] min-w-[10rem] sm:min-w-[unset] max-h-[8.5rem] min-h-[7.875rem] w-auto h-full sm:mb-2":
            isScreenShareMode,
        })}
        videoClassName="max-w-[15rem] max-h-[8.5rem] min-w-[unset] w-auto h-full [&>video]:relative mb-2 mr-2 sm:mr-0 flex-1 border rounded-2xl border-neutral-800"
      />
    ),
    [isScreenShareMode],
  );

  return (
    <StageContainer className="@container" rounded>
      {inSession ? (
        <>
          <InSessionStageEffects />
          <SessionGrid
            remoteParticipantComponent={RemoteParticipant}
            thumbnail={thumbnail}
          />
          <InSessionTimer />
          {!isScreenShareMode && (
            <SelfThumbnailVideo
              className="absolute bottom-14"
              hideSelfViewClassName="bottom-14"
              videoClassName="max-w-[8rem]"
              showSetPositionMenu
              shouldShowHideSelf
              showExpandVideoToggle={false}
            />
          )}
        </>
      ) : (
        <>
          <Background />
          {!isMobile && (
            <SelfThumbnailVideo
              showExpandVideoToggle={true}
              shouldShowHideSelf
              showSetPositionMenu
              className="absolute"
            />
          )}
          <div
            className="relative h-full w-full flex flex-col overflow-y-auto overflow-x-hidden p-8"
            // The main page content is a scrollable container that potentially
            // does not contain any interactive elements. The tabindex is added
            // to ensure that the container is focusable and can be scrolled
            // using the keyboard.
            // See:
            // - https://www.a11yproject.com/posts/how-to-use-the-tabindex-attribute/#scrollable-overflow-containers
            // - https://chromestatus.com/feature/5231964663578624
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
          >
            <ProviderAvatar />
            {isMobile && (
              <SelfThumbnailVideo
                shouldShowHideSelf={false}
                showExpandVideoToggle={false}
                showSetPositionMenu={false}
                className="self-center relative my-10"
                videoClassName="min-h-[14rem] max-h-[18rem] min-w-[90vw] max-w-screen-sm"
              />
            )}
            <div className="flex flex-col flex-grow items-center justify-center">
              <ProviderGreeting className="mb-8" />
              <SchedulePreview />
              {isTranscriptionGateEnabled && <AvsCtaPill className="mt-6" />}
            </div>
          </div>
        </>
      )}
    </StageContainer>
  );
});

function PageEffects() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  useInitLocalTracks();
  useHandleDeviceChanges();
  useInitTwilioSyncClient();
  useVisitorPresenceMap();
  useSyncMoodCheckin();
  useProviderTwilioRoomListeners();
  useRecordUnaccompaniedProviderInterval();
  useNetworkQualityLogging({
    onOpenSettings: () => setDrawerState(DrawerState.SETTINGS),
  });
  useSessionFocus();
  useInitProviderGlobalDDLoggerContext();
  useInitProviderUserTracking();
  useResetVideoOnMobileNavigation();
  useMaxDurationWarning();
  useDetectStaleVersionInterval(!useAtomValue(twilioRoomAtom));
  useShowNotificationInFaviconAndTitle();
  useInitProviderChatClient();
  useUpdateTrackStatsInterval();
  useAnnouncementToast();

  useInactiveTabPerformanceOptimization();
  const providerShortId = useAtomValue(providerShortIdAtom);
  useWaitingRoomTrackingEvent(
    EventTypeEnum.PROVIDER_WAITING_ROOM,
    providerShortId,
  );

  return null;
}

export function Page() {
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const inSessionPatientInformation = useAtomValue(
    inSessionPatientInformationAtom,
  );
  const providerShortId = useAtomValue(providerShortIdAtom);
  const inSession = !!useAtomValue(participantCountAtom);
  const openClientInformationDrawer = useOpenClientInformationCallback();

  usePromptFollowUpToast(
    providerShortId,
    inSessionPatientInformation?.patientShortId,
  );

  useEffect(
    function sendLoggingEventsOnPageLoad() {
      if (inSession) {
        // TODO migrate to segment
        sendLoggingEvents(TrackingEvents.PROVIDER_ENTER_SESSION_ROOM);
      }
    },
    [inSession],
  );

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const toggleDrawerState = (drawer: DrawerState): void => {
    if (drawerState === drawer) {
      setDrawerState(null);
      return;
    }
    setDrawerState(drawer);
  };

  const openHelpModal = useCallback(() => {
    sendLoggingEvents(TrackingEvents.ANY_HELP_REQUEST);
    sendLoggingEvents(TrackingEvents.PROVIDER_HELP_REQUEST);
    setIsHelpModalOpen(true);
  }, []);

  return (
    <>
      <PageEffects />
      <PageProviders theme={Theme.DARK}>
        <PageContainer>
          <HelpModal
            isOpen={isHelpModalOpen}
            onClose={() => setIsHelpModalOpen(false)}
          />
          <Navbar
            drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
            drawerState={drawerState}
            forSettingsButtonDrawerState={DrawerState.SETTINGS}
            onClickSettingsButton={() =>
              toggleDrawerState(DrawerState.SETTINGS)
            }
            onClickNeedHelpButton={openHelpModal}
            TranscriptionPillComponent={TranscriptionPillWrapper}
            {...nonEssentialElementProps}
            className={classNames(
              nonEssentialElementProps.className,
              "order-1",
            )}
          />
          <AudioVideoControls
            openHelpModal={openHelpModal}
            recordingControlComponent={RecordingControl}
            additionalLeftControls={
              <ClientsDrawerButton drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID} />
            }
            additionalRightControls={inSession && <CompleteSessionButton />}
            chatButtonComponent={
              <ChatDrawerButton drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID} />
            }
            clientInformationButtonComponent={
              <ClientInformationButton
                drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
              />
            }
            additionalOptionsComponent={
              <AdditionalOptionsMenu
                drawerId={PUSH_DRAWER_ARIA_CONTROLS_ID}
                drawerState={drawerState}
                onClickSettingsButton={() =>
                  toggleDrawerState(DrawerState.SETTINGS)
                }
                onClickClientsButton={
                  inSession
                    ? () => {
                        toggleDrawerState(DrawerState.CLIENTS);
                        toast.dismiss();
                      }
                    : undefined
                }
                onClickClientInformationButton={() =>
                  openClientInformationDrawer(
                    inSessionPatientInformation?.visitorUuid ?? "",
                  )
                }
              />
            }
            {...nonEssentialElementProps}
            className={classNames(
              nonEssentialElementProps.className,
              "order-3",
            )}
          />
          <PushDrawerContainer
            drawer={<PushDrawer />}
            // The push drawer must come _after_ the buttons that can trigger it
            // in the DOM. This is so that a keyboard user can tab forward from
            // the triggering button to the drawer. However, the drawer and
            // stage are meant to be visually positioned _before_ the control
            // buttons, so we reorder them within their flex container.
            className="order-2"
            id={PUSH_DRAWER_ARIA_CONTROLS_ID}
          >
            <Stage />
          </PushDrawerContainer>
        </PageContainer>
        <StartNewSessionConfirmationModal />
      </PageProviders>
    </>
  );
}
