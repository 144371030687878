import { useAtomValue } from "jotai";
import { twMerge } from "tailwind-merge";
import { connectedAtAtom, participantCountAtom } from "../twilio";
import { useInterval } from "usehooks-ts";
import { useCallback, useState } from "react";
import { timeDifference } from "./utils";
import classNames from "classnames";

export function InSessionTimer({
  connectedAt,
  getCurrentTimestamp,
}: {
  connectedAt?: number;
  getCurrentTimestamp: () => number;
}) {
  const [time, setTime] = useState<string>("00:00");
  const participantCount = useAtomValue(participantCountAtom);

  useInterval(
    useCallback(
      function calculateTime() {
        if (!connectedAt) return;
        setTime(timeDifference(connectedAt, getCurrentTimestamp()));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [connectedAt],
    ),
    1000,
  );

  if (!connectedAt) return;

  return (
    <div
      data-testid="in-session-timer"
      className={twMerge(
        classNames(
          "absolute font-mono bottom-2 right-2 px-4 py-2 font-medium bg-neutral-800 text-neutral-300 rounded-xl",
          { "bg-neutral-900": participantCount > 1 },
        ),
      )}
    >
      {time}
    </div>
  );
}

export function InSessionTimerWrapper() {
  const connectedAt = useAtomValue(connectedAtAtom);

  return (
    <InSessionTimer
      connectedAt={connectedAt}
      getCurrentTimestamp={() => Date.now()}
    />
  );
}
