import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast, ToastVariant } from "../components";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { hasRecordingAtom } from "./session-recording/state";
import { useAtomValue } from "jotai";
type Props = {
  className: string;
  onClose: () => void;
  onCleanup: () => void;
};

export function SessionCompletedToast({
  className,
  hasRecording,
  shouldDisableTranscription,
  onClose,
  onCleanup,
}: Props & {
  hasRecording: boolean;
  shouldDisableTranscription?: boolean;
}) {
  const showTranscriptionCopy = hasRecording && !shouldDisableTranscription;
  const text = showTranscriptionCopy
    ? "Session completed. Transcription processing in progress."
    : "Session completed.";
  return (
    <Toast
      className={twMerge("p-4", className)}
      variant={ToastVariant.Success}
      onClose={onClose}
      onCleanup={onCleanup}
    >
      <div className="flex flex-row w-full gap-3 items-center">
        <Icon
          aria-hidden
          icon={faCircleCheck}
          className="text-xl text-neutral-800"
        />
        <Text variant="sm" className="grow block text-left">
          {text}
        </Text>
      </div>
    </Toast>
  );
}

export function SessionCompletedToastWrapper({
  shouldDisableTranscription,
  ...props
}: Props & { shouldDisableTranscription?: boolean }) {
  const hasRecording = useAtomValue(hasRecordingAtom);
  return (
    <SessionCompletedToast
      {...props}
      hasRecording={hasRecording}
      shouldDisableTranscription={shouldDisableTranscription}
    />
  );
}
