import { useGate } from "statsig-react";
import { useAtomValue } from "jotai";
import { participantCountAtom } from "../";
import { disableTranscriptionForMultipleParticipants } from "../../statsig/gates";

/**
 * The below code is to prevent sessions with multiple participants from surfacing
 * Transcription features due to AI quality issues identified in these types of sessions.
 * It is behind a feature flag and can be removed when we improve AI features for these sessions.
 */

export function useShouldDisableTranscriptionForMultipleParticipants() {
  const { value: shouldDisableTranscriptionForMultipleParticipants } = useGate(
    disableTranscriptionForMultipleParticipants,
  );

  const hasMultipleParticipants = useAtomValue(participantCountAtom) > 1;

  return (
    hasMultipleParticipants && shouldDisableTranscriptionForMultipleParticipants
  );
}
