import { useAtomValue } from "jotai";
import { TranscriptionPill } from "../../twilio/TranscriptionPill";
import { useRecordingControl } from "../../twilio/audio-video-controls/useRecordingControl";
import { ConsentStatus } from "../../twilio/types";
import { transcriptionFeatureEnabledAtom } from "./state";
import { useParams } from "react-router-dom";
import {
  patientConsentAtom,
  providerConsentAtom,
} from "../../twilio/messages/state";
import { participantCountAtom } from "../../twilio";

export function TranscriptionPillWrapper() {
  const { providerShortId, patientShortId } = useParams();
  const patientConsent = useAtomValue(patientConsentAtom).value;
  const providerConsent = useAtomValue(providerConsentAtom).value;
  const hasMultipleParticipants = useAtomValue(participantCountAtom) > 1;

  const isTranscriptionEnabled = useAtomValue(transcriptionFeatureEnabledAtom);
  const { isRecording, isVisible } = useRecordingControl(
    providerShortId,
    patientShortId,
    isTranscriptionEnabled,
  );

  const bothPartiesConsented =
    patientConsent === ConsentStatus.OPTED_IN &&
    providerConsent === ConsentStatus.OPTED_IN;

  if (
    !isVisible ||
    !(bothPartiesConsented || isRecording) ||
    hasMultipleParticipants
  ) {
    return null;
  }

  return (
    <TranscriptionPill
      isRecording={isRecording}
      patientConsent={patientConsent}
    />
  );
}
