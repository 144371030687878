import { useAtomValue, useSetAtom } from "jotai";
import {
  visitorPresenceAtom,
  visitorPresenceMapSidAtom,
  waitingRoomConversationSidsByVisitorIdAtom,
  waitingVisitorsAtom,
} from "./state";
import { useInterval } from "usehooks-ts";
import {
  NOTIFICATION_VOLUME,
  getVisitorPresenceUpdateIntervalMs,
} from "../config";
import { debounce } from "../utils";
import { useShowNewVisitorToastCallback } from "./useShowNewVisitorToastCallback";
import {
  OnInitializeItemsCallback,
  useSyncMap,
} from "../twilio/sync/useSyncMap";
import { useCallback, useEffect, useRef } from "react";
import { participantCountAtom } from "../twilio/state";
import swiftGestureToneUri from "../assets/audio/notification-tone-swift-gesture.mp3?url";
import { filterActive, mapItemToVisitor, mapItemsToVisitors } from "./utils";
import { useAtomCallback } from "jotai/utils";
import { gracefullyPlayAudio } from "../audio";
import { useCreateTwilioVisitorPresenceMapAccessCallback } from "./useCreateTwilioVisitorPresenceMapAccess";
import { logger } from "../datadog/logger";
import { useWaitingRoomConversationCallbacks } from "./chat/useWaitingRoomConversationCallbacks";
import { VisitorPresence, VisitorPresenceMap } from "../types";
import { SyncMapEventHandler } from "../twilio/types";
import { useGetVisitorChatUser } from "./chat/useGetVisitorChatParticipant";
import { useLazyGetPatientByShortId } from "./useGetPatientByShortId";

const DEBOUNCE_TIME_MS = 1000;

const playNewVisitorWaitingSound = debounce(() => {
  const ringtone = new Audio(swiftGestureToneUri);
  ringtone.volume = NOTIFICATION_VOLUME;
  gracefullyPlayAudio(ringtone);
}, DEBOUNCE_TIME_MS);

/**
 * Returns a callback that, given a `visitorId`, returns true if the visitor is
 * actively in a conversation with the provider
 *
 * Returns 'noop' if the conversation does not exist, because we do not want to
 * block admission if there was an issue with conversation creation
 */
export function useCheckChattyVisitorCallback() {
  const getVisitorChatUser = useGetVisitorChatUser();
  const getConversations = useAtomCallback(
    useCallback((get) => get(waitingRoomConversationSidsByVisitorIdAtom), []),
  );

  return useCallback(
    async (visitorId: string): Promise<"noop" | boolean> => {
      if (!getConversations()[visitorId]) return "noop";
      return !!(await getVisitorChatUser(visitorId))?.isOnline;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export function useVisitorPresenceMap() {
  const [fetchPatient] = useLazyGetPatientByShortId();
  const visitorPresenceMapSid = useAtomValue(visitorPresenceMapSidAtom);
  const getParticipantCount = useAtomCallback(
    useCallback((get) => get(participantCountAtom), []),
  );
  const getWaitingVisitors = useAtomCallback(
    useCallback((get) => get(waitingVisitorsAtom), []),
  );
  const showNewVisitorToast = useShowNewVisitorToastCallback();
  const setVisitors = useSetAtom(visitorPresenceAtom);
  const createTwilioVisitorPresenceMapAccess_ =
    useCreateTwilioVisitorPresenceMapAccessCallback();
  const createTwilioVisitorPresenceMapAccess = () => {
    isCreatingVisitorPresenceMapAccessRef.current = true;
    return createTwilioVisitorPresenceMapAccess_().finally(() => {
      isCreatingVisitorPresenceMapAccessRef.current = false;
    });
  };
  const isCreatingVisitorPresenceMapAccessRef = useRef(true);
  const { joinWaitingRoomConversation, leaveWaitingRoomConversation } =
    useWaitingRoomConversationCallbacks();
  const joinVisitorsWaitingRoomConversation = (
    visitorId: string,
    { waitingRoomConversationSid }: VisitorPresence,
  ) => {
    if (!waitingRoomConversationSid) return;
    return joinWaitingRoomConversation(visitorId, waitingRoomConversationSid);
  };
  const checkChattyVisitor = useCheckChattyVisitorCallback();

  useEffect(
    function initVisitorPresenceMapAccess() {
      createTwilioVisitorPresenceMapAccess();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const visitorPresenceMap = useSyncMap({
    mapSid: visitorPresenceMapSid,
    onInitializeItems: useCallback<OnInitializeItemsCallback>(
      async (items) => {
        const upsertVisitors = (visitors: VisitorPresenceMap) => {
          setVisitors((prev) => ({
            ...prev,
            ...visitors,
          }));
          return visitors;
        };

        const showToastIfVisitorsPresent = (visitors: VisitorPresenceMap) => {
          const numberOfVisitors = Object.keys(visitors).length;
          if (numberOfVisitors > 0) {
            const [firstVisitorKey] = Object.keys(visitors);
            const isMultipleVisitors = numberOfVisitors > 1;
            const participantName = isMultipleVisitors
              ? `${numberOfVisitors} people`
              : (visitors[firstVisitorKey].name ?? "");
            const visitorUuid = isMultipleVisitors
              ? undefined
              : firstVisitorKey;

            // this may not be 100% logically correct, however, we are passing
            // the shortId in order to prevent multiple patients (with different
            // shortIds) from entering the same room. by logic, choosing the
            // first should be ok since these are all (presumably) active
            const visitorPatientShortId =
              visitors[firstVisitorKey].patientShortId;
            showNewVisitorToast(
              visitorPatientShortId,
              participantName,
              visitorUuid,
            );
            if (!getParticipantCount()) {
              playNewVisitorWaitingSound();
            }
          }
        };

        const joinConversations = async (visitors: VisitorPresenceMap) =>
          Promise.allSettled(
            Object.entries(visitors).map((entry) =>
              joinVisitorsWaitingRoomConversation(...entry),
            ),
          );

        const filterChattyVisitors = async (visitors: VisitorPresenceMap) => {
          const visitorIds = Object.keys(visitors);

          const chattyVisitors = new Set();
          for (const visitorId of visitorIds) {
            if (await checkChattyVisitor(visitorId)) {
              chattyVisitors.add(visitorId);
            }
          }
          return Object.entries(visitors).reduce(
            (acc, [visitorId, visitorMap]) => {
              if (!chattyVisitors.has(visitorId)) return acc;
              return {
                ...acc,
                [visitorId]: visitorMap,
              };
            },
            {},
          );
        };

        let activeVisitors = filterActive(mapItemsToVisitors(items));
        await joinConversations(activeVisitors);
        activeVisitors = await filterChattyVisitors(activeVisitors);
        upsertVisitors(activeVisitors);
        showToastIfVisitorsPresent(activeVisitors);
        Object.values(activeVisitors).forEach((v) => {
          fetchPatient({ variables: { patientShortId: v.patientShortId } });
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    onItemAdded: useCallback<SyncMapEventHandler>(
      ({ item }) => {
        const visitor = mapItemToVisitor(item);
        if (!visitor) return;
        const visitorAlreadyExists = getWaitingVisitors()[item.key];
        if (visitorAlreadyExists) return;
        if (!getParticipantCount()) {
          playNewVisitorWaitingSound();
        }
        const participant = visitor[item.key] ?? {};
        showNewVisitorToast(
          participant.patientShortId,
          participant.name ?? "",
          item.key,
        );
        setVisitors((prev) => ({ ...prev, ...visitor }));

        joinVisitorsWaitingRoomConversation(item.key, visitor[item.key]);
        fetchPatient({
          variables: { patientShortId: visitor[item.key].patientShortId },
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    onItemUpdated: useCallback<SyncMapEventHandler>(
      async ({ item }) => {
        const visitor = mapItemToVisitor(item);
        if (!visitor) return;
        await joinVisitorsWaitingRoomConversation(item.key, visitor[item.key]);
        if (!(await checkChattyVisitor(item.key))) return;
        setVisitors((prev) => ({ ...prev, ...visitor }));
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    onItemRemoved: useCallback<SyncMapEventHandler>(
      ({ item }) => {
        const { key } = item;
        setVisitors(({ [key]: _, ...rest }) => rest);

        const visitor = mapItemToVisitor(item);
        const { waitingRoomConversationSid } = visitor?.[key] ?? {};
        if (!waitingRoomConversationSid) return;
        leaveWaitingRoomConversation(key, waitingRoomConversationSid);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  });

  useInterval(
    useCallback(
      function removeInactiveVisitors() {
        setVisitors((prev) => filterActive(prev));
      },
      [setVisitors],
    ),
    getVisitorPresenceUpdateIntervalMs(),
  );

  useInterval(
    useCallback(
      function refreshVisitorPresenceMapAccess() {
        const expirationDateStr = visitorPresenceMap?.dateExpires;
        const isLoading = isCreatingVisitorPresenceMapAccessRef.current;
        const isExpired = (expirationDate: Date) =>
          expirationDate <= new Date();
        const shouldRefreshVisitorPresenceMapAccess =
          !isLoading &&
          expirationDateStr &&
          isExpired(new Date(expirationDateStr));
        if (!shouldRefreshVisitorPresenceMapAccess) return;
        logger.info("Refreshing visitor presence map access");
        createTwilioVisitorPresenceMapAccess();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [visitorPresenceMap?.dateExpires],
    ),
    1000,
  );
}
