import {
  MeasureConnected,
  MeasureConnectedProps,
} from "@grow-therapy-team/sprout-ui";
import { PageComponentProps } from "..";
import { MeasureSchemaAndAnswers } from "./types";
import { FC } from "react";

export function MeasuresContent({
  page,
  children,
  measureComponent: MeasureComponent = MeasureConnected,
}: PageComponentProps<MeasureSchemaAndAnswers> & {
  children?: React.ReactNode;
  measureComponent?: FC<MeasureConnectedProps>;
}) {
  if (!page.schema) return;

  return (
    <div className="flex flex-col gap-y-3">
      <MeasureComponent
        schema={page.schema}
        provider={page.schema.isProviderSpecific ? page.provider : undefined}
      />
      {children}
    </div>
  );
}
