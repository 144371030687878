import { Card, Text } from "@grow-therapy-team/sprout-ui";
import { GroupedFormData, QuestionTypeEnum } from "./types";
import { IntakeFormAnswer } from "./IntakeFormAnswer";
import { keep } from "../../utils";

type IntakeFormSectionProps = {
  selectedForm: GroupedFormData;
};

export function IntakeFormSection({ selectedForm }: IntakeFormSectionProps) {
  return (
    <div
      className="flex flex-col"
      data-testid="client-information.forms.intake-form"
    >
      {Object.entries(selectedForm.formQuestionsWithAnswers).map(
        ([sectionTitle, questions]) => (
          <Card
            key={sectionTitle}
            title={sectionTitle}
            className="font-medium pb-2 fs-exclude"
            data-dd-privacy="mask"
          >
            {keep(questions, (question, i) => {
              const answer = question.answer;

              if (!answer) return null;

              const isDivider = question.type === QuestionTypeEnum.DIVIDER;
              const isSectionTitle =
                question.type === QuestionTypeEnum.SECTION_TITLE;

              const isFlexAnswer = answer === "Yes" || answer === "No";
              return (
                <div
                  className="fs-exclude"
                  data-dd-privacy="mask"
                  key={`${question.question}-${i}`}
                >
                  {isDivider ? (
                    <hr className="w-full mb-4 mt-4 text-neutral-400" />
                  ) : (
                    <div
                      className={`${
                        isFlexAnswer
                          ? "flex flex-row justify-between mb-2 mt-2"
                          : "mb-2 mt-2"
                      }`}
                    >
                      <Text
                        className={`${
                          isSectionTitle
                            ? "font-semibold font-large mt-4 pt-4 mb-4"
                            : "font-medium"
                        }`}
                      >
                        {question.question}
                      </Text>
                      <IntakeFormAnswer
                        questionWithAnswer={question}
                        isDivider={isDivider}
                        isSectionTitle={isSectionTitle}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </Card>
        ),
      )}
    </div>
  );
}
