import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export enum VolumeBarTestIds {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

interface VolumeMonitorProps {
  className?: string;
  levelIndicatorClassName?: string;
  levelIndicatorCount?: number;
  volume?: number;
}
export function VolumeMonitor({
  className,
  levelIndicatorClassName,
  levelIndicatorCount = 6,
  volume = 0,
}: VolumeMonitorProps) {
  return (
    <ul
      className={twMerge("flex items-center", className)}
      aria-label={`Volume level is ${volume} out of ${levelIndicatorCount}`}
    >
      {[...Array(levelIndicatorCount)].map((_, i) => {
        const isActiveVolumeBar = volume >= i + 1;
        return (
          <li
            key={i}
            data-testid={
              isActiveVolumeBar
                ? VolumeBarTestIds.ACTIVE
                : VolumeBarTestIds.INACTIVE
            }
            className={twMerge(
              classNames(
                {
                  "bg-green-400": isActiveVolumeBar,
                  "bg-neutral-600": !isActiveVolumeBar,
                },
                "w-6 sm:w-9 h-4 rounded-md mr-1",
              ),
              levelIndicatorClassName,
            )}
          />
        );
      })}
    </ul>
  );
}
