import { Icon, Text } from "@grow-therapy-team/sprout-ui";
import { Toast } from "./Toast";
import { twMerge } from "tailwind-merge";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";

export function NewMessageToast({
  senderName,
  messageContent,
  onClose,
  onClick,
  className,
}: {
  senderName: string;
  messageContent: string;
  onClose?: () => void;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <Toast className={twMerge("p-4", className)} isBanner onClose={onClose}>
      <button
        className="flex gap-4 w-full align-start"
        onClick={onClick}
        aria-label={`Open new message from ${senderName}`}
      >
        <Icon
          aria-hidden
          icon={faMessage}
          className="text-lilac-700 p-4 bg-lilac-400 rounded-full"
        />
        <div className="pr-3">
          <Text
            data-dd-privacy="mask"
            data-dd-action-name="Click on new message toast title"
            className="block font-medium text-lilac-700 text-left fs-exclude"
          >
            {senderName}
          </Text>
          <Text
            data-dd-privacy="mask"
            data-dd-action-name="Click on new message toast body"
            variant="xs"
            className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100vw-12rem)] sm:max-w-[15rem] text-left fs-exclude"
          >
            {messageContent}
          </Text>
        </div>
      </button>
    </Toast>
  );
}
