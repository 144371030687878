import {
  Button,
  ButtonSize,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  IconButton,
  MeasureConnected,
  MeasureSchemaObject,
  MeasureSchemaType,
  useGetMeasureSchemaByType,
} from "@grow-therapy-team/sprout-ui";
import {
  DrawerState,
  drawerStateAtom,
  selectedClientUserShortIdAtom,
} from "../state";
import { useAtomValue, useSetAtom } from "jotai";
import { useSubmitCSSRSMeasureCallback } from "./useSubmitCSSRSMeasureCallback";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

type CSSRSFormProps = {
  onClickBackButton: () => void;
  onSubmit: () => Promise<void>;
  connectedMeasureComponent: React.ReactNode;
  isLoading?: boolean;
};

const CSSRSFormHeader = ({
  onClickBackButton,
}: {
  onClickBackButton: () => void;
}) => {
  return (
    <DrawerHeader>
      <div className="flex items-center">
        <IconButton
          onClick={onClickBackButton}
          className="mr-4 w-8 h-8 flex justify-center items-center"
          size={ButtonSize.Small}
          aria-label="Go back"
          iconDefinition={faArrowLeft}
        />
        <DrawerTitle>C-SSRS – Suicide Risk</DrawerTitle>
      </div>
    </DrawerHeader>
  );
};

export const CSSRSForm = ({
  onClickBackButton,
  onSubmit,
  connectedMeasureComponent,
  isLoading,
}: CSSRSFormProps) => {
  return (
    <>
      <CSSRSFormHeader onClickBackButton={onClickBackButton} />
      <DrawerBody>{connectedMeasureComponent}</DrawerBody>
      <DrawerFooter>
        <Button loading={isLoading} onClick={onSubmit} className="!ml-auto">
          Submit
        </Button>
      </DrawerFooter>
    </>
  );
};

export const CSSRSFormWrapper = () => {
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);
  const { data: cssrsSchemaData } = useGetMeasureSchemaByType(
    MeasureSchemaType.COLUMBIA_SUICIDE_SEVERITY_RATING_SCALE,
  );
  const [handleSubmitMeasure, { isLoading }] = useSubmitCSSRSMeasureCallback();

  const setDrawerState = useSetAtom(drawerStateAtom);
  const onClickBackButton = () => {
    setDrawerState(DrawerState.MEASURE_RESPONSES);
  };

  if (!cssrsSchemaData?.measureSchema || !clientUserShortId) {
    return;
  }

  return (
    <CSSRSForm
      connectedMeasureComponent={
        <MeasureConnected
          schema={cssrsSchemaData.measureSchema as MeasureSchemaObject}
        />
      }
      onClickBackButton={onClickBackButton}
      onSubmit={handleSubmitMeasure}
      isLoading={isLoading}
    />
  );
};
