import {
  ClientMeasureOptOutBannerConnected,
  Heading,
  Tag,
  TagUse,
} from "@grow-therapy-team/sprout-ui";
import { FormEntry } from "./FormEntry";
import { CombinedFormDataEntriesByFormTitle } from "./types";
import { FormListTaskItemWrapper as FormListTaskItem } from "./form-list-item";
import { sortFormsByFormType } from "../../components/forms/utils";
import { pipe } from "remeda";
import {
  IntakeFormListItemWrapper as IntakeFormListItem,
  MeasuresFormListItemWrapper as MeasuresFormListItem,
  SafetyPlanFormListItemWrapper as SafetyPlanFormListItem,
} from "../measures/FormListItems";
import { useShouldUseMICInfra } from "../../hooks/measures/useShouldUseMICInfra";
import { useShouldShowClientOptOutBanners } from "../../hooks/measures/useShouldShowClientOptOutBanners";
import { useAtomValue } from "jotai";
import { selectedClientUserShortIdAtom } from "../state";

type ClientInformationFormsAndQuestionnairesProps = {
  completedForms?: CombinedFormDataEntriesByFormTitle;
  hasNewForms?: boolean;
};
export function ClientInformationFormsAndQuestionnaires({
  completedForms,
  hasNewForms,
}: ClientInformationFormsAndQuestionnairesProps) {
  const shouldUseMICInfra = useShouldUseMICInfra();
  const shouldShowClientOptOutBanners = useShouldShowClientOptOutBanners();
  const clientUserShortId = useAtomValue(selectedClientUserShortIdAtom);

  const sortedCompletedForms = completedForms
    ? pipe(
        completedForms,
        Object.keys,
        function keysToMostRecentForms(keys) {
          return keys.map((key) => completedForms[key][0]);
        },
        sortFormsByFormType,
      )
    : [];

  return (
    <div className="flex flex-col mt-6">
      <Heading variant="md">
        Forms & questionnaires
        {hasNewForms && (
          <Tag
            aria-label="Client has new forms"
            use={TagUse.Lilac}
            className="ml-2"
          >
            New
          </Tag>
        )}
      </Heading>
      {shouldUseMICInfra ? (
        <>
          {shouldShowClientOptOutBanners && clientUserShortId && (
            <ClientMeasureOptOutBannerConnected
              clientUserShortId={clientUserShortId}
              renderContext="telehealth"
            />
          )}
          <MeasuresFormListItem />
          <IntakeFormListItem />
          <SafetyPlanFormListItem />
        </>
      ) : (
        <>
          <FormListTaskItem />
          {sortedCompletedForms.map((form) => (
            <FormEntry form={form} key={form.formId} />
          ))}
        </>
      )}
    </div>
  );
}
