import { twMerge } from "tailwind-merge";

export function VerticalDivider({ className }: { className?: string }) {
  return (
    <div
      className={twMerge(
        "h-full",
        // use a right-border to create the divider
        "border-r-[1px] border-solid",
        // border-neutral-700 is just currentColor, so text-neutral-700 does the work
        "border-neutral-700 text-neutral-700",
        className,
      )}
    />
  );
}
