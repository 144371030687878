import {
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from "@grow-therapy-team/sprout-ui";
import { HelpModalItem } from "../components/help-modal/HelpModalItem";
import { AudioVideoTroubleshootActionItemWrapper as BaseAudioVideoTroubleshootActionItem } from "../components/help-modal/AudioVideoTroubleshootActionItem";
import { CopyButton } from "../components/CopyButton";
import {
  CLIENT_TELEHEALTH_HELP_ARTICLE,
  PROVIDER_CLIENT_NO_SHOW_ARTICLE,
  PROVIDER_TELEHEALTH_HELP_ARTICLE,
} from "../components/help-modal/constants";
import { useInitAvailableDevices } from "../twilio/devices/useDevices";
import { useSetAtom } from "jotai";
import { DrawerState, drawerStateAtom } from "./state";
import { ReactNode } from "react";

type HelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  audioVideoTroubleshootActionItem?: ReactNode;
};

export function HelpModal({
  isOpen,
  onClose,
  audioVideoTroubleshootActionItem: AudioVideoTroubleshootActionItem,
}: HelpModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Need help?</ModalHeader>
      <ModalBody className="sm:py-8 flex flex-col gap-6">
        <Text className="text-neutral-800" as="h2">
          Common issues
        </Text>
        <ol className="list-style-none flex flex-col gap-6">
          {AudioVideoTroubleshootActionItem}
          <HelpModalItem
            heading="My client is trying to get into the session"
            description={
              <>
                When a client is in the waiting room, you’ll be notified via a
                message in the bottom left of your screen. Select{" "}
                <span className="font-medium">“View”</span> to open the client
                queue, and then select client(s) to{" "}
                <span className="font-medium">“Admit.”</span> If the message
                disappears, select the{" "}
                <span className="font-medium">“Clients”</span> button to open
                the client queue.
              </>
            }
          />
          <HelpModalItem
            heading="My client isn't here"
            description={
              <>
                Your client has a 15-minute grace period. When 15 minutes have
                passed, see our{" "}
                <Link
                  external
                  className="text-neutral-800 hover:text-neutral-900"
                  href={PROVIDER_CLIENT_NO_SHOW_ARTICLE}
                >
                  no-show guidelines
                </Link>{" "}
                for next steps.
              </>
            }
          />
          <HelpModalItem
            heading="I need help with a different issue"
            description={
              <>
                See our{" "}
                <Link
                  external
                  className="text-neutral-800 hover:text-neutral-900"
                  href={PROVIDER_TELEHEALTH_HELP_ARTICLE}
                >
                  Telehealth guide
                </Link>{" "}
                for additional help. If your client is experiencing issues, they
                may use the{" "}
                <Link
                  external
                  externalIcon={null}
                  className="text-neutral-800 hover:text-neutral-900"
                  href={CLIENT_TELEHEALTH_HELP_ARTICLE}
                >
                  Client Telehealth guide
                </Link>{" "}
                <CopyButton
                  copyText={CLIENT_TELEHEALTH_HELP_ARTICLE}
                  label="Copy Client Telehealth Guide"
                />
              </>
            }
          />
        </ol>
      </ModalBody>

      <ModalFooter
        actions={[
          {
            key: "close-modal",
            use: "secondary",
            onClick: onClose,
            actionContent: "Done",
          },
        ]}
      >
        <Text variant="sm">
          If you need additional support, reach out to support in your provider
          portal.
        </Text>
      </ModalFooter>
    </Modal>
  );
}

export function HelpModalWrapper(
  props: Pick<HelpModalProps, "isOpen" | "onClose">,
) {
  useInitAvailableDevices();
  const setDrawerState = useSetAtom(drawerStateAtom);
  const openSettings = () => {
    props.onClose();
    setDrawerState(DrawerState.SETTINGS);
  };

  return (
    <HelpModal
      {...props}
      audioVideoTroubleshootActionItem={
        <BaseAudioVideoTroubleshootActionItem onOpenSettings={openSettings} />
      }
    />
  );
}
