import {
  AccessibleSelect,
  Checkbox,
  Icon,
  IconBadge,
  InputControl,
  MenuDivider,
  Radio,
  RadioGroup,
  SelectOptionAtom,
  Tag,
  TagUse,
  Text,
  toast,
} from "@grow-therapy-team/sprout-ui";
import {
  getDeviceOptionsFromDeviceList,
  useUpdateAvailableDevicesInterval,
} from "../../twilio/devices/useDevices";
import {
  QualityMode,
  availableDevicesAtom,
  currentCameraIdAtom,
  permissionDeniedAtom,
  shouldOverrideSuggestedRoomVideoQualityAtom,
  videoQualityAtom,
  canOverrideSuggestedRoomVideoQualityAtom,
  shouldSuggestPerformanceModeAtom,
  QualityPersistanceType,
  useVideoQualityCallbacks,
  consolidateQualityMode,
  LocalAudioVideoThumbnailProps,
  useChangeCameraDeviceCallback,
  ChangeCameraStatusCode,
  localVideoTrackStateAtom,
  LocalTrackState,
} from "../../twilio";
import { useAtomValue, useSetAtom } from "jotai";
import { BlockedPermissionMessageWrapper as BlockedPermissionMessage } from "./BlockedPermissionMessage";
import { NoAccessVariant } from "../../twilio/audio-video-controls/NoAccessToast";
import { MIRROR_VIDEO_LABEL } from "./constants";
import { useStoredAudioVideoSettings } from "../../twilio/useStoredAudioVideoSettings";
import { useDebounceCallback } from "usehooks-ts";
import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";
import { Toast, ToastVariant } from "..";
import {
  faVideo,
  faVideoSlash,
  faWifiExclamation,
} from "@fortawesome/pro-solid-svg-icons";

type VideoSettingsTabProps = {
  cameraOptions: SelectOptionAtom[];
  setCamera: (selectedId: string) => void;
  selectedCamera?: string;
  thumbnailComponent: React.FC<Omit<LocalAudioVideoThumbnailProps, "name">>;
  videoIsBlocked: boolean;
  videoIsLoading?: boolean;
  isMirrored: boolean;
  setIsMirrored: (isMirrored: boolean) => void;
  qualityMode: QualityMode;
  onChangeQualityMode?: (qualityMode: QualityMode) => void;
  shouldShowAutoOptimizationAlert?: boolean;
  storedQualityMode?: QualityMode;
};

export function VideoSettingsTab({
  cameraOptions,
  setCamera,
  selectedCamera,
  thumbnailComponent: LocalAudioVideoThumbnail,
  videoIsBlocked,
  videoIsLoading,
  isMirrored,
  setIsMirrored,
  qualityMode,
  onChangeQualityMode,
  shouldShowAutoOptimizationAlert,
  storedQualityMode,
}: VideoSettingsTabProps) {
  const shouldDisableFields = videoIsBlocked || videoIsLoading;
  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-center">
        <LocalAudioVideoThumbnail
          videoClassName="max-h-none max-w-none w-full h-[216px]"
          className="w-full h-full"
          showExpandVideoToggle={false}
          isSettingsThumbnail={true}
          isSpeaking={false}
          shouldShowHideSelf={false}
          videoOffContent={
            <IconBadge
              size="xl"
              className="bg-neutral-800"
              iconClassName="text-coral-600"
              icon={faVideoSlash}
            />
          }
        />
      </div>
      <InputControl
        label="Camera"
        disabled={shouldDisableFields}
        className="mb-0"
      >
        <AccessibleSelect
          onChange={(value: string) => setCamera(value)}
          options={cameraOptions}
          value={selectedCamera ?? cameraOptions[0]?.value}
          placeholder={videoIsBlocked ? "No access" : "Select"}
        />
      </InputControl>
      <Checkbox
        label={MIRROR_VIDEO_LABEL}
        checked={isMirrored}
        onChange={() => setIsMirrored(!isMirrored)}
        disabled={shouldDisableFields}
      />
      {videoIsBlocked && (
        <BlockedPermissionMessage noAccessVariant={NoAccessVariant.Camera} />
      )}
      <MenuDivider />
      <div className="flex flex-col gap-4">
        <InputControl
          label="Quality"
          className="mb-0"
          disabled={shouldDisableFields}
        >
          <RadioGroup
            onChange={(event) =>
              onChangeQualityMode?.(event.target.value as QualityMode)
            }
            value={consolidateQualityMode(qualityMode)}
            className="pt-5"
            layout="column"
            name="Quality"
          >
            <Radio label="Optimize for quality" value={QualityMode.High} />
            <Radio
              // @ts-ignore FIXME: (CARE-685) Need to update label type to include ReactNode
              label={
                <>
                  Optimize for performance
                  <Tag
                    className="ml-5 xs:ml-2 sm:ml-5 inline-flex gap-2 w-content items-center"
                    use={TagUse.Neutral}
                    aria-label="Check this box if you have poor connectivity"
                  >
                    <Icon icon={faWifiExclamation} aria-hidden />
                    <span>for low connectivity</span>
                  </Tag>
                </>
              }
              value={QualityMode.Low}
            />
          </RadioGroup>
        </InputControl>
        {shouldShowAutoOptimizationAlert &&
          // Prevents the alert from showing if the user has already set their
          // own quality mode to low I.e. the quality changes that are reflected
          // back by the remote participant won't result in this alert showing
          storedQualityMode !== QualityMode.Low && (
            <Toast
              className="flex gap-3"
              data-testid="video-settings.auto-optimization-alert"
              variant={ToastVariant.Neutral}
            >
              <Icon icon={faVideo} aria-hidden />
              <Text>
                A participant has optimized the session video for performance.
                You may update this setting for yourself.
              </Text>
            </Toast>
          )}
      </div>
    </div>
  );
}

export function VideoSettingsTabWrapper(
  props: Pick<VideoSettingsTabProps, "thumbnailComponent">,
) {
  useUpdateAvailableDevicesInterval();

  const devices = useAtomValue(availableDevicesAtom);
  const shouldShowAutoOptimizationAlert = useAtomValue(
    shouldSuggestPerformanceModeAtom,
  );
  const { videoIsBlocked } = useAtomValue(permissionDeniedAtom);
  const localVideoState = useAtomValue(localVideoTrackStateAtom);
  const videoIsLoading =
    !localVideoState || localVideoState === LocalTrackState.LOADING;
  const { storedAudioVideoSettings, setStoredAudioVideoSettings } =
    useStoredAudioVideoSettings();
  const onToggleMirroredVideo = (isMirrored: boolean) => {
    setStoredAudioVideoSettings((settings) => ({
      ...settings,
      videoIsMirrored: isMirrored,
    }));
  };
  const cameraOptions = getDeviceOptionsFromDeviceList(
    devices?.videoInputDevices,
  );
  const changeCameraDevice = useChangeCameraDeviceCallback();
  const videoQuality = useAtomValue(videoQualityAtom);
  const getCanOverrideSuggestedRoomVideoQuality = useAtomCallback(
    useCallback((get) => get(canOverrideSuggestedRoomVideoQualityAtom), []),
  );
  const setShouldOverrideSuggestedQualityMode = useSetAtom(
    shouldOverrideSuggestedRoomVideoQualityAtom,
  );
  const { changeVideoQuality } = useVideoQualityCallbacks();

  // This debounce shouldn't be strictly necessary, but it seems like the select
  // component can call its onChange twice on Android devices which causes
  // unexpected behavior
  const setCamera = useDebounceCallback(
    useCallback(
      async (deviceId: string) => {
        const { status, code } = await changeCameraDevice(deviceId);
        if (status === "success") return;
        switch (code) {
          case ChangeCameraStatusCode.ERROR:
            toast.error("Unable to change camera device.");
            return;
          case ChangeCameraStatusCode.BACKGROUND_ERROR:
            toast.error(
              "Unable to set background after changing camera device.",
            );
            return;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    100,
  );
  const onChangeVideoQualityMode = useDebounceCallback(
    useCallback(
      async (qualityMode: QualityMode) => {
        const { status } = await changeVideoQuality(qualityMode, {
          persistanceType: QualityPersistanceType.STORAGE_AND_MEMORY,
        });
        if (status === "success") {
          if (getCanOverrideSuggestedRoomVideoQuality()) {
            setShouldOverrideSuggestedQualityMode(true);
          }
          return;
        }
        toast.error("Unable to change your video quality due to an issue.");
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
    200,
  );
  const selectedCamera = useAtomValue(currentCameraIdAtom);
  const correspondingCameraId = cameraOptions.some(
    (option) => option.value === selectedCamera,
  )
    ? selectedCamera
    : storedAudioVideoSettings?.inputVideoDeviceId;

  return (
    <VideoSettingsTab
      cameraOptions={cameraOptions}
      setCamera={setCamera}
      selectedCamera={correspondingCameraId}
      videoIsBlocked={videoIsBlocked}
      videoIsLoading={videoIsLoading}
      isMirrored={storedAudioVideoSettings?.videoIsMirrored ?? false}
      qualityMode={videoQuality}
      setIsMirrored={onToggleMirroredVideo}
      onChangeQualityMode={onChangeVideoQualityMode}
      shouldShowAutoOptimizationAlert={shouldShowAutoOptimizationAlert}
      storedQualityMode={storedAudioVideoSettings?.videoQualityMode}
      {...props}
    />
  );
}
