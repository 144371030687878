import { RemoteParticipant as RemoteParticipantType } from "twilio-video";
import { RemoteParticipantWrapper as RemoteParticipant } from "./RemoteParticipant";
import { RemoteScreenShare } from "./RemoteScreenShare";
import { LocalScreenShare } from "./LocalScreenShare";
import { StopScreenShareButtonWrapper as StopScreenShareButton } from "../audio-video-controls/StopScreenShareButton";
import {
  parseIdentity,
  participantsAtom,
  remoteScreenShareParticipantAtom,
} from "..";
import { Text } from "@grow-therapy-team/sprout-ui";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";
import { ScrollableGallery } from "../../components/ScrollableGallery";
interface ScreenShareLayoutProps {
  remoteParticipants: RemoteParticipantType[];
  remoteScreenShareParticipant?: RemoteParticipantType;
  thumbnail: ReactNode;
  remoteParticipantComponent: typeof RemoteParticipant;
}

export function ScreenShareLayout({
  remoteParticipants,
  remoteScreenShareParticipant,
  remoteParticipantComponent: RemoteParticipantComponent,
  thumbnail,
}: ScreenShareLayoutProps) {
  const { name: screenShareParticipantName } = parseIdentity(
    remoteScreenShareParticipant?.identity ?? "",
  );

  return (
    <div className="bg-neutral-900 rounded-2xl w-screen sm:w-full sm:h-full p-2 bg-neutral-800">
      <StopScreenShareButton />
      {remoteScreenShareParticipant && (
        <div data-dd-privacy="mask">
          <Text
            variant="sm"
            className="text-neutral-100 text-center pb-2 fs-exclude"
          >
            {screenShareParticipantName} is sharing screen
          </Text>
        </div>
      )}
      <div className="flex flex-col sm:flex-row h-[calc(100%-3rem)] justify-between items-center">
        {remoteScreenShareParticipant ? (
          <RemoteScreenShare />
        ) : (
          <LocalScreenShare />
        )}
        <div className="order-1 sm:order-2 sm:h-full w-full sm:w-[unset]">
          <ScrollableGallery>
            {thumbnail}
            {remoteParticipants.map((remoteParticipant) => {
              return (
                <RemoteParticipantComponent
                  key={remoteParticipant.identity}
                  className="max-w-[15rem] min-w-[10rem] sm:min-w-[unset] max-h-[8.5rem] min-h-[7.875rem] w-auto h-full sm:mb-2 [&:not(:last-child)]:mr-2 sm:[&:not(:last-child)]:mr-0 flex-1 rounded-2xl border-neutral-800 border"
                  remoteParticipant={remoteParticipant}
                />
              );
            })}
          </ScrollableGallery>
        </div>
      </div>
    </div>
  );
}

export function ScreenShareLayoutWrapper({
  thumbnail,
  remoteParticipantComponent: RemoteParticipantComponent,
}: Pick<ScreenShareLayoutProps, "thumbnail" | "remoteParticipantComponent">) {
  const participants = useAtomValue(participantsAtom);
  const remoteScreenShareParticipant = useAtomValue(
    remoteScreenShareParticipantAtom,
  );

  return (
    <ScreenShareLayout
      remoteParticipants={participants}
      remoteScreenShareParticipant={remoteScreenShareParticipant}
      remoteParticipantComponent={RemoteParticipantComponent}
      thumbnail={thumbnail}
    />
  );
}
