import { useState } from "react";
import {
  TimelineAppointment,
  useGetUpcomingAppointmentsQuery,
} from "../../useGetUpcomingAppointments";
import {
  formatShortAppointmentDate,
  formatTimeRange,
  getBrowserTimezone,
} from "../../schedule-appointment/utils";
import { Button, Icon, Text } from "@grow-therapy-team/sprout-ui";
import { formatUpcomingAppointments } from "../utils";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  selectedClientInfoAtom,
} from "../../state";
import { scheduledPatientInformationAtom } from "../../schedule-preview/state";
import { RefreshAppointmentsCallout } from "./RefreshAppointmentsCallout";
import { mightNeedToRefreshAppointmentListAtom } from "../../schedule-appointment/state";
import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";

export function UpcomingClientAppointmentsTab({
  upcomingAppointments,
  isLoading,
  onAppointmentClick,
  onClickAddAppointment,
  onRefresh,
}: {
  upcomingAppointments: TimelineAppointment[];
  isLoading?: boolean;
  onAppointmentClick: (appointment: TimelineAppointment) => void;
  onClickAddAppointment: () => void;
  onRefresh?: () => void;
}) {
  const [
    mightNeedToRefreshAppointmentList,
    setMightNeedToRefreshAppointmentList,
  ] = useAtom(mightNeedToRefreshAppointmentListAtom);

  if (isLoading) {
    return (
      <div className="flex justify-center pt-5">
        <Icon
          aria-hidden={false}
          aria-label="Loading..."
          size="xl"
          className="text-lilac-700 fa-spin"
          icon={fadSpinnerThird}
        />
      </div>
    );
  }
  if (!upcomingAppointments || upcomingAppointments.length === 0) {
    return (
      <div className="pt-5">
        <p> No appointments scheduled.</p>
        <Button onClick={onClickAddAppointment} use="link" className="mt-4">
          Add appointment
        </Button>
      </div>
    );
  }

  return (
    <div className="pt-5">
      {mightNeedToRefreshAppointmentList && (
        <RefreshAppointmentsCallout
          onClickRefresh={() => {
            setMightNeedToRefreshAppointmentList(false);
            onRefresh?.();
          }}
        />
      )}
      <ul>
        {upcomingAppointments.map((appointment) => {
          const isRecurringAppointment =
            appointment?.recurringAppointmentGroupingId;
          return (
            <li key={appointment.id}>
              <Button
                use="unstyled"
                className="text-left px-0 py-4 w-full"
                onClick={() => {
                  onAppointmentClick(appointment);
                }}
              >
                <Text className="font-medium">
                  {formatShortAppointmentDate(appointment?.timeStart)}
                  {isRecurringAppointment && (
                    <Icon
                      aria-hidden={false}
                      aria-label={"(recurring appointment)"}
                      icon={faRepeat}
                      className="ml-2"
                    />
                  )}
                </Text>
                <Text className="text-neutral-700 font-normal">
                  <span className="sr-only">from</span>
                  {formatTimeRange(
                    appointment.timeStart,
                    appointment.timeEnd,
                  )}{" "}
                  {getBrowserTimezone()}
                </Text>
              </Button>
              <hr className="text-neutral-600" />
            </li>
          );
        })}
      </ul>
      <Button onClick={onClickAddAppointment} use="link" className="mt-4">
        Add appointment
      </Button>
    </div>
  );
}

export function UpcomingClientAppointmentsTabWrapper({
  selectedPatientShortId,
}: {
  selectedPatientShortId: string;
}) {
  const [timeStart, _] = useState(new Date().toISOString());
  const selectedWaitingClient = useAtomValue(selectedClientInfoAtom);
  const setDrawerState = useSetAtom(drawerStateAtom);
  const [appointmentInfo, setAppointmentInfo] = useAtom(
    scheduledPatientInformationAtom,
  );

  const {
    data: upcomingAppointmentsData,
    loading,
    refetch,
  } = useGetUpcomingAppointmentsQuery(selectedPatientShortId, timeStart);

  const onAppointmentClick = (appointment: TimelineAppointment) => {
    const {
      appointmentShortId,
      patientId,
      appointmentType,
      recurringAppointmentGrouping,
    } = appointment;
    setDrawerState(DrawerState.EDIT_APPOINTMENT);
    setAppointmentInfo({
      appointmentStartTime: appointment.timeStart,
      appointmentEndTime: appointment.timeEnd,
      appointmentLocation: appointment.inOffice,
      appointmentId: appointment.id,
      patientId: patientId?.toString() || "",
      appointmentType,
      recurringAppointmentGrouping,
      appointmentShortId,
      patientShortId: selectedPatientShortId,
      name: selectedWaitingClient?.name ?? appointmentInfo?.name,
    });
  };

  const appointments =
    upcomingAppointmentsData?.currentProvider?.providerAppointments?.edges;

  const upcomingAppointments = formatUpcomingAppointments(appointments);
  return (
    <UpcomingClientAppointmentsTab
      upcomingAppointments={upcomingAppointments}
      isLoading={loading}
      onAppointmentClick={onAppointmentClick}
      onClickAddAppointment={() => {
        setDrawerState(DrawerState.ADD_APPOINTMENT);
      }}
      onRefresh={refetch}
    />
  );
}
