import { useCallback, useState } from "react";
import { participantCountAtom, twilioRoomAtom } from "../../twilio/state";
import {
  MeetingAccessEventType,
  useAdmitVisitorMutation,
} from "./useAdmitVisitorMutation";
import { useAtomCallback } from "jotai/utils";
import { getTwilioConfig } from "../../twilio/config";
import {
  toast as sproutToast,
  shouldPromptForCSSRS,
} from "@grow-therapy-team/sprout-ui";
import toast from "react-hot-toast";
import { useConnectTwilioRoomCallback } from "../../twilio/useConnectTwilioRoomCallback";
import { useSetAtom } from "jotai";
import {
  DrawerState,
  drawerStateAtom,
  inSessionPatientInformationAtom,
  joiningVisitorIdsAtom,
  visitorPresenceAtom,
} from "../state";
import { deadlinesByVisitorIdFamily } from "../delayed-entry/state";
import { addImmutablyToSet, removeImmutablyFromSet } from "../../utils";
import { useGetCurrentCachedProviderCallback } from "../useGetCurrentCachedProviderCallback";
import { logger } from "../../datadog/logger";
import { useGetTwilioRoomTokenCallback } from "./useGetTwilioRoomTokenCallback";
import { roomToDiagnosticInfo } from "../../twilio/utils";
import { useDisconnectTwilioRoomCallback } from "../useDisconnectTwilioRoomCallback";
import { Mutex } from "async-mutex";
import { CallbackResponse } from "../../types";
import { Room } from "twilio-video";
import { useWaitForClientToJoinCallback } from "./useWaitForClientToJoinCallback";
import { useUpsertSessionConversationCallback } from "../chat/useUpsertSessionConversationCallback";
import { useCheckChattyVisitorCallback } from "../useVisitorPresenceMap";
import { omit } from "remeda";
import { ClientFinishingUpToastWrapper as ClientFinishingUpToast } from "../delayed-entry/ClientFinishingUpToast";
import { addSeconds } from "date-fns";
import { Statsig } from "statsig-react";
import { cssrsMeasuresGate, delayedEntryGate } from "../../statsig/gates";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { useCSSRSPromptToastCallback } from "../measures/useCSSRSPromptToastCallback";

export enum CreateRoomStatusCode {
  CREATED = "CREATED",
  ALREADY_EXISTS = "ALREADY_EXISTS",
}

export type AdmitClientCallback = (
  visitorUuid: string,
  visitorName: string,
  patientShortId: string,
) => Promise<void | NodeJS.Timeout>;

export function useGracefullyRequestVisitorAdmissionCallback() {
  const [admitVisitor] = useAdmitVisitorMutation();
  const getCurrentCachedProvider = useGetCurrentCachedProviderCallback();
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setInSessionPatientInformation = useSetAtom(
    inSessionPatientInformationAtom,
  );
  const getInSessionPatientInformation = useAtomCallback(
    useCallback((get) => get(inSessionPatientInformationAtom), []),
  );
  const getDeadline = useAtomCallback(
    useCallback(
      (get, _, visitorId: string) => get(deadlinesByVisitorIdFamily(visitorId)),
      [],
    ),
  );
  const { refetch: fetchPatient } = useGetPatientByShortId();
  const showCSSRSPromptToast = useCSSRSPromptToastCallback();

  return useCallback(
    async ({
      conversationSid,
      room,
      visitorName,
      visitorUuid,
      patientShortId,
      appointmentShortId,
    }: {
      conversationSid?: string;
      room: Room;
      visitorName: string;
      visitorUuid: string;
      patientShortId: string;
      appointmentShortId?: string;
    }) => {
      try {
        const deadline = getDeadline(visitorUuid);
        const admittedVisitor = await admitVisitor({
          variables: {
            conversationSid,
            eventType: MeetingAccessEventType.GRANTED,
            roomName: room.name,
            visitorName,
            visitorUuid,
            patientShortId,
            ...(deadline && { deadline }),
          },
        });
        const inSessionPatient = getInSessionPatientInformation();
        setInSessionPatientInformation({
          patientShortId,
          visitorUuid,
          name: visitorName,
          inSessionAppointmentShortId: appointmentShortId,
        });

        if (inSessionPatient?.patientShortId !== patientShortId) {
          fetchPatient({ patientShortId }).then(
            function checkIfCSSRSToastShouldPop(response): void {
              if (Statsig.checkGate(cssrsMeasuresGate)) {
                const clientMeasures =
                  response.data?.patientChart?.measureBundles;
                const patientName =
                  response.data?.patientChart?.preferredShortName ||
                  visitorName;
                if (clientMeasures && shouldPromptForCSSRS(clientMeasures)) {
                  showCSSRSPromptToast(patientName);
                }
              }
            },
          );
        }

        if (Statsig.checkGate(delayedEntryGate)) {
          toast.custom(
            (t) => (
              <ClientFinishingUpToast
                className="bottom-left-toast"
                visitorId={visitorUuid}
                participantName={visitorName}
                onClose={() => toast.remove(t.id)}
                onOpenQueue={() => setDrawerState(DrawerState.CLIENTS)}
              />
            ),
            {
              position: "bottom-left",
              duration: Infinity,
            },
          );
        }

        return admittedVisitor;
      } catch (error) {
        sproutToast.error("There was an issue admitting the client");
        logger.error(
          "Unable to admit visitor",
          {
            providerShortId: (await getCurrentCachedProvider())?.shortId,
            visitorUuid,
            ...roomToDiagnosticInfo(room),
          },
          error as Error,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

const twilioRoomMutex = new Mutex();

export function useGetOrConnectTwilioRoomCallback() {
  const getTwilioRoom = useAtomCallback(
    useCallback((get) => get(twilioRoomAtom), []),
  );
  const getVisitorById = useAtomCallback(
    useCallback((get) => get(visitorPresenceAtom), []),
  );

  const getTwilioRoomToken = useGetTwilioRoomTokenCallback();
  const [connectTwilioRoom] = useConnectTwilioRoomCallback();

  return useCallback(
    async (
      visitorId: string,
    ): Promise<
      | undefined
      | (CallbackResponse<Room> & {
          value: Room;
          appointmentShortId: string | undefined;
        })
    > => {
      const releaseMutex = await twilioRoomMutex.acquire();
      try {
        const { patientShortId } = getVisitorById()[visitorId] ?? {};
        const twilioRoomTokenData = await getTwilioRoomToken(patientShortId);

        const existingRoom = getTwilioRoom();
        if (existingRoom) {
          return {
            status: "success",
            code: CreateRoomStatusCode.ALREADY_EXISTS,
            value: existingRoom,
            appointmentShortId: twilioRoomTokenData?.appointmentShortId,
          };
        }

        if (!twilioRoomTokenData?.token) {
          sproutToast.error("There was an issue creating the room");
          return;
        }

        const newRoom = await connectTwilioRoom({
          tokenOverride: twilioRoomTokenData.token,
        });
        if (!newRoom) {
          sproutToast.error("There was an issue connecting to the room");
          return;
        }

        return {
          status: "success",
          code: CreateRoomStatusCode.CREATED,
          value: newRoom,
          appointmentShortId: twilioRoomTokenData?.appointmentShortId,
        };
      } finally {
        releaseMutex();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

/**
 * Returns a callback fn that can be used to admit a patient to the waiting
 * room. It will load until a participant connects to the room or the timeout is
 * reached. It will also indicating the visitor it's waiting for in Jotai.
 */
export function useAdmitClientCallback(): [
  AdmitClientCallback,
  { loading?: boolean },
] {
  const [waitingForParticipant, setWaitingForParticipant] = useState<boolean>();
  const getParticipantCount = useAtomCallback(
    useCallback((get) => get(participantCountAtom), []),
  );
  const loading = waitingForParticipant;
  const getOrConnectTwilioRoom = useGetOrConnectTwilioRoomCallback();
  const setJoiningVisitorIds = useSetAtom(joiningVisitorIdsAtom);
  const belowParticipantCapacity = useCallback(
    () => {
      if (getParticipantCount() >= getTwilioConfig().maxParticipants - 1) {
        sproutToast.error(
          "You have reached the maximum number of participants allowed in a session",
        );
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const setVisitors = useSetAtom(visitorPresenceAtom);
  const gracefullyRequestVisitorAdmission =
    useGracefullyRequestVisitorAdmissionCallback();
  const checkChattyVisitor = useCheckChattyVisitorCallback();
  const stopWaitingForClientToJoin = async (visitorId: string) => {
    if (!(await checkChattyVisitor(visitorId))) {
      setVisitors((prev) => omit(prev, [visitorId]));
    }
    setJoiningVisitorIds((prev) => removeImmutablyFromSet(prev, visitorId));
    setWaitingForParticipant(false);
  };
  const setVisitorDeadline = useAtomCallback(
    useCallback(
      (_, set, visitorId: string, deadline: Date | null) =>
        set(deadlinesByVisitorIdFamily(visitorId), deadline),
      [],
    ),
  );
  const startWaitingForClientToJoin = (visitorId: string) => {
    setJoiningVisitorIds((prev) => addImmutablyToSet(prev, visitorId));
    setWaitingForParticipant(true);
    setVisitorDeadline(visitorId, addSeconds(new Date(), 30));
  };
  const disconnectTwilioRoom = useDisconnectTwilioRoomCallback();
  const disconnectFromCreatedRoom = (code?: string) => {
    if (code === CreateRoomStatusCode.CREATED && !getParticipantCount()) {
      disconnectTwilioRoom();
    }
  };
  const waitForClientToJoin = useWaitForClientToJoinCallback();
  const upsertConversationCallback = useUpsertSessionConversationCallback();

  const callback = useCallback<AdmitClientCallback>(
    async (
      visitorId: string,
      visitorName: string,
      patientShortId: string,
    ): Promise<void | NodeJS.Timeout> => {
      startWaitingForClientToJoin(visitorId);
      let createRoomResult: Awaited<ReturnType<typeof getOrConnectTwilioRoom>>;
      let upsertConversationResult: Awaited<
        ReturnType<typeof upsertConversationCallback>
      >;
      if (
        !belowParticipantCapacity() ||
        !(createRoomResult = await getOrConnectTwilioRoom(visitorId)) ||
        // Chat creation is a side-effect that should not stop admission
        ((upsertConversationResult =
          await upsertConversationCallback(visitorId)) &&
          false) ||
        !(await gracefullyRequestVisitorAdmission({
          room: createRoomResult.value,
          visitorName,
          visitorUuid: visitorId,
          conversationSid: upsertConversationResult?.value?.sid,
          patientShortId,
          appointmentShortId: createRoomResult?.appointmentShortId,
        }))
      ) {
        await stopWaitingForClientToJoin(visitorId);
        disconnectFromCreatedRoom(createRoomResult?.code);
        return;
      }

      return waitForClientToJoin({
        visitorId,
        onTimeout: () => {
          stopWaitingForClientToJoin(visitorId);
          setVisitorDeadline(visitorId, null);
        },
        onFailure: () => {
          disconnectFromCreatedRoom(createRoomResult?.code);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [callback, { loading }];
}
