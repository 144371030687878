import {
  Card,
  NudgeList,
  Heading,
  NudgeObject,
  NudgeSchemaTrigger,
} from "@grow-therapy-team/sprout-ui";
import { getAppConfig } from "../../config";
import { useSetAtom } from "jotai";
import { drawerStateAtom } from "../state";
import { useTrackEvent } from "../../segment/segment";
import { createNudgeOnClickHandlers } from "./utils";

export interface ClinicalConsiderationsSectionProps {
  nudges: NudgeObject[];
  onClickHandlers?: Partial<Record<NudgeSchemaTrigger, () => void>>;
}

export function ClinicalConsiderationsSection({
  nudges,
  onClickHandlers,
}: ClinicalConsiderationsSectionProps) {
  return (
    <div
      className="flex flex-col mb-6 gap-y-6"
      data-testid="clinical-considerations-section.body.content"
    >
      <Heading variant="md">Clinical considerations</Heading>
      <Card data-testid="clinical-considerations-section.card.content">
        <NudgeList
          nudges={nudges}
          onClickHandlers={onClickHandlers}
          context="telehealth"
        />
      </Card>
    </div>
  );
}

export function ClinicalConsiderationsSectionWrapper({
  nudges,
  patientId,
}: {
  nudges: NudgeObject[];
  patientId: string;
}) {
  const providerDashboardReferralUri = `${
    getAppConfig().providerOrigin
  }/refer-client/filters/${patientId}`;

  const { userClicked } = useTrackEvent();
  const onClickHandlers = createNudgeOnClickHandlers(
    nudges,
    useSetAtom(drawerStateAtom),
    providerDashboardReferralUri,
    userClicked,
  );

  return (
    <ClinicalConsiderationsSection
      nudges={nudges}
      onClickHandlers={onClickHandlers}
    />
  );
}
