import {
  MeasureSchemaType,
  MeasureValidationError,
  toast,
  useGetMeasureSchemaByType,
  useSubmitMeasureResponseCallback,
} from "@grow-therapy-team/sprout-ui";
import { useCallback } from "react";
import {
  DrawerState,
  drawerStateAtom,
  providerAtom,
  selectedBundleAtom,
  selectedClientInfoAtom,
  selectedClientUserShortIdAtom,
} from "../state";
import { useSetAtom } from "jotai";
import {
  GET_PATIENT_BY_PATIENT_SHORT_ID_GQL,
  useLazyGetPatientByShortId,
} from "../useGetPatientByShortId";
import { logger } from "../../datadog";
import { useAtomCallback } from "jotai/utils";

export const useSubmitCSSRSMeasureCallback = (): [
  () => Promise<void>,
  { isLoading: boolean },
] => {
  const getPatientShortId = useAtomCallback(
    (get) => get(selectedClientInfoAtom)?.patientShortId,
  );
  const getProviderShortId = useAtomCallback(
    (get) => get(providerAtom)?.userData?.shortId,
  );
  const getClientUserShortId = useAtomCallback((get) =>
    get(selectedClientUserShortIdAtom),
  );
  const setDrawerState = useSetAtom(drawerStateAtom);
  const setSelectedBundle = useSetAtom(selectedBundleAtom);
  const { data: cssrsSchemaData } = useGetMeasureSchemaByType(
    MeasureSchemaType.COLUMBIA_SUICIDE_SEVERITY_RATING_SCALE,
  );
  const [submitMeasure, { isLoading }] = useSubmitMeasureResponseCallback();
  const [getClientData, { loading: isPatientDataLoading }] =
    useLazyGetPatientByShortId(getPatientShortId());
  const handleSubmitMeasure = useCallback(async () => {
    const clientUserShortId = getClientUserShortId();
    const providerShortId = getProviderShortId();
    if (
      !clientUserShortId ||
      !cssrsSchemaData?.measureSchema ||
      !providerShortId
    ) {
      logger.error("Could not submit measure due to missing values");
      toast.error("Unable to submit form due to missing values.");
      return;
    }
    try {
      const { data: measureResponseData } = await submitMeasure({
        clientUserShortId: clientUserShortId,
        schema: cssrsSchemaData.measureSchema,
        providerShortId,
        shouldShowErrorToast: false,
        startedAt: new Date().toISOString(),
        apolloOptions: {
          awaitRefetchQueries: true,
          refetchQueries: [GET_PATIENT_BY_PATIENT_SHORT_ID_GQL],
        },
      });
      const { data: patientData } = await getClientData();
      const submittedMeasureResponse =
        measureResponseData?.submitMeasureResponse?.measureResponse;
      const updatedMeasureBundles = patientData?.patientChart?.measureBundles;
      const newMeasureBundle = updatedMeasureBundles?.find(
        (bundle) =>
          bundle.measureBundleItems[0]?.measureResponse
            ?.measureResponseShortId ===
          submittedMeasureResponse?.measureResponseShortId,
      );

      if (submittedMeasureResponse && updatedMeasureBundles) {
        setSelectedBundle(newMeasureBundle);
      } else {
        setSelectedBundle(undefined);
      }
      setDrawerState(DrawerState.MEASURE_RESPONSES);
    } catch (err) {
      if (!(err instanceof MeasureValidationError)) {
        logger.error(
          "Could not submit measure",
          { clientUserShortId },
          err as Error,
        );
        toast.error("Unable to submit form due to an expected error.");
      }
    }
  }, [
    cssrsSchemaData,
    submitMeasure,
    setDrawerState,
    setSelectedBundle,
    getClientData,
    getClientUserShortId,
    getProviderShortId,
  ]);

  return [
    handleSubmitMeasure,
    { isLoading: isLoading || isPatientDataLoading },
  ];
};
