import {
  Icon,
  IconButton,
  tailwindMerge,
  DRAWER_TITLE_ID,
} from "@grow-therapy-team/sprout-ui";
import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { usePrevious } from "react-use";
import {
  ErrorBoundary as BaseErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import { logger } from "../datadog/logger";
import { Toast, ToastVariant } from "./Toast";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "../hooks";
import { twMerge } from "tailwind-merge";
import { Theme } from "../state";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";

function FallbackComponent({ error }: FallbackProps) {
  useEffect(
    function logError() {
      logger.error(
        "The push drawer crashed after encountering an unexpected error; displaying fallback",
        error,
      );
    },
    [error],
  );

  return (
    <div
      className="pointer-events-none relative overflow-hidden flex flex-col w-full h-full pt-24 items-center"
      data-testid="push-drawer.fallback"
    >
      <Toast
        className="bg-transparent mb-4"
        data-testid="push-drawer.fallback.error-message"
        variant={ToastVariant.Neutral}
      >
        <span>
          <Icon className="text-coral-600 mr-4" icon={faWarning} aria-hidden />
          Something went wrong. Please try again later.
        </span>
      </Toast>
    </div>
  );
}

function ErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <BaseErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BaseErrorBoundary>
  );
}

export function PushDrawer({
  isOpen = true,
  onClose,
  children,
  placement = "right",
  className,
  closeButtonRef: closeButtonRefProp,
}: {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  className?: string;
  placement?: "left" | "right";
  closeButtonRef: React.RefObject<HTMLButtonElement>;
}) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const prevChildren = usePrevious(children);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(isOpen);
    }
  }, [isOpen]);

  if (!shouldRender) {
    return <></>;
  }

  return (
    <aside
      aria-labelledby={DRAWER_TITLE_ID}
      className={tailwindMerge(
        classNames(
          "shrink border border-neutral-800 bg-neutral-300 flex flex-col", // general styling regardless of screen size
          "max-sm:z-20 max-sm:absolute top-0 bottom-0 max-h-full h-screen w-screen", // mobile styling
          "sm:top-3 sm:bottom-3 sm:rounded-xl sm:h-full sm:max-w-[30rem]", // larger than mobile styling
          {
            "animate-slide-up sm:animate-slide-in-right right-0 sm:right-3":
              isOpen && placement === "right",
            "animate-slide-down sm:animate-slide-out-left":
              !isOpen && placement === "right",
            "animate-slide-up sm:animate-slide-in-left left-3":
              isOpen && placement === "left",
            "animate-slide-down sm:animate-slide-out-right":
              !isOpen && placement === "left",
            "transition-all max-sm:opacity-5 sm:w-0": !isOpen,
          },
          className,
        ),
      )}
      onAnimationEnd={(): void => {
        if (!isOpen) {
          setShouldRender(false);
        } else {
          closeButtonRefProp.current?.focus();
        }
      }}
    >
      <IconButton
        ref={closeButtonRefProp}
        aria-label="Close drawer"
        iconDefinition={faXmark}
        onClick={onClose}
        className={classNames(
          "absolute right-0 my-4 mx-6 h-8 w-8 flex-none items-center justify-center sm:my-6 sm:mx-8 md:mx-10 md:my-8",
          {
            hidden: !isOpen,
          },
        )}
      />
      <ErrorBoundary>{isOpen ? children : prevChildren}</ErrorBoundary>
    </aside>
  );
}

export function PushDrawerContainer({
  drawer,
  children,
  className,
  id,
}: {
  drawer: ReactNode;
  children?: ReactNode;
  className?: string;
  id: string;
}) {
  const { theme } = useTheme();
  return (
    <div
      className={classNames(
        { "border-t border-b": theme === Theme.LIGHT },
        "flex flex-row overflow-hidden h-full w-full",
        className,
      )}
    >
      {children}
      <div
        className={twMerge(
          classNames({
            "sm:m-4": theme === Theme.LIGHT,
            "sm:mx-2": theme === Theme.DARK,
          }),
        )}
        id={id}
      >
        {drawer}
      </div>
    </div>
  );
}
