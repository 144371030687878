import { Text } from "@grow-therapy-team/sprout-ui";
import { ErrorPage } from "./ErrorPage";

export function Error404Page() {
  const content = (
    <div className="flex flex-col items-center justify-center">
      <Text className="text-neutral-700">
        Sorry, something went wrong on our end. Contact support through your
        portal.
      </Text>
    </div>
  );

  return <ErrorPage title="Page not found" subtitle={content} />;
}
