import { Text, Icon } from "@grow-therapy-team/sprout-ui";
import { GroupedFormData, FormType } from "../types";
import { HTMLAttributes } from "react";
import { FormListItemBadge } from "./FormListItemBadge";
import { FormListItemContent } from "./FormListItemContent";
import { FormListItemLayout } from "./FormListItemLayout";
import { FormListItemBadgeVariant } from "./types";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

type FormListItemProps = {
  form: GroupedFormData;
  onClick: HTMLAttributes<HTMLButtonElement>["onClick"];
};

function getFormTitle(form: GroupedFormData) {
  return form.formType === FormType.Gad7 || form.formType === FormType.Phq9
    ? `${form.formType} - ${form.formTitle}`
    : form.formTitle;
}

export function FormListItem({ form, onClick }: FormListItemProps) {
  return (
    <FormListItemLayout buttonProps={{ onClick }}>
      <FormListItemBadge
        className={{
          "px-8 py-4": !form.score,
        }}
        variant={
          form.highAcuity
            ? FormListItemBadgeVariant.High
            : FormListItemBadgeVariant.Low
        }
      >
        {" "}
        <div className="flex-row text-center leading-tight">
          {form?.score ? (
            <div className="pb-1 font-medium">{form.score.toString()}</div>
          ) : (
            <Icon aria-hidden={false} aria-label="Completed" icon={faCheck} />
          )}
        </div>
      </FormListItemBadge>
      <FormListItemContent
        title={<Text>{getFormTitle(form)}</Text>}
        subtitle={
          form.createdAt && (
            <Text variant="sm" as="span" className="text-neutral-700">
              Submitted {form.createdAt}
            </Text>
          )
        }
      />
    </FormListItemLayout>
  );
}
