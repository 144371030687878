export const dynamicConfig = "telehealth_config";
export const announcementConfig = "telehealth_announcement";
export const rebrandGate = "rebrand_theme";
export const telehealthTranscriptionGate = "telehealth_transcription";
export const delayedEntryGate = "telehealth_delayed_entry";
export const measuresGate = "telehealth_measures";
export const clientDirectConsentGate = "telehealth_client_direct_consent";
export const cssrsMeasuresGate = "cssrs_measure_enabled";
export const disableTranscriptionForMultipleParticipants =
  "telehealth_disable_transcription_for_multiple_participants";
export const autoStartRecordingGate = "telehealth_auto_start_recording";
export const enableClientMeasureConfigurationRequest =
  "enable_client_measure_configuration_request";
export const telehealthMeasureProgress = "telehealth_measure_progress";
export const micNudgesGate = "mic_nudges_fe";
