import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Icon, IconButton } from "@grow-therapy-team/sprout-ui";
import { secondsToMilliseconds } from "date-fns";
import { useState } from "react";

export function CopyButton({
  copyText,
  label,
}: {
  copyText: string;
  label?: string;
}) {
  const [copied, setCopied] = useState(false);

  return (
    <span aria-live="polite">
      {copied ? (
        <Icon
          icon={faCheck}
          size="sm"
          aria-hidden={false}
          aria-label="Copied"
        />
      ) : (
        <IconButton
          aria-label={label ? label : "Copy"}
          iconDefinition={faCopy}
          className="inline shadow-border-none px-0 py-0"
          onClick={(): void => {
            setCopied(true);
            navigator.clipboard
              .writeText(copyText)
              .then(() =>
                setTimeout(() => setCopied(false), secondsToMilliseconds(1.5)),
              );
          }}
        />
      )}
    </span>
  );
}
