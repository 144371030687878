import { useEffect } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";
import { useTrackEvent } from "../segment/segment";
import { EventTypeEnum, UserViewedParams } from "../segment/types";

const DEFAULT_VISIBILITY_THRESHOLD = 0.5;

interface UseCaptureComponentViewInput<P extends EventTypeEnum>
  extends IntersectionOptions {
  type: NonNullable<P>;
  properties: UserViewedParams<P>;
  isReady?: boolean;
}

/**
 * `useCaptureComponentView` is a custom React hook that captures when a
 * component comes into view.  It uses the `useInView` and `useTrackEvent` hooks
 * to determine when the component is in view and to track the event in Segment.
 *
 * Example:
 * ```
 * const { ref } = useCaptureComponentView({
 *   type: 'viewedEventName',
 *   properties: { id: '123', name: 'Test Component' },
 * });
 *
 * // In your component
 * <div ref={ref}>Your content here</div>
 * ```
 */
export function useCaptureComponentView<P extends EventTypeEnum>({
  type,
  properties,
  threshold = DEFAULT_VISIBILITY_THRESHOLD,
  isReady = true,
  ...intersectionOptions
}: UseCaptureComponentViewInput<P>) {
  const { ref, inView } = useInView({ threshold, ...intersectionOptions });
  const { userViewed } = useTrackEvent();

  useEffect(() => {
    if (!inView || !isReady) return;

    userViewed(type, properties);
  }, [inView, isReady, properties, type, userViewed]);

  return { ref };
}
