import { PushDrawer, SettingsDrawer } from "../components";
import { ClientsDrawerWrapper as ClientsDrawer } from "./clients-drawer";
import { useAtom } from "jotai";
import { currentConversationSidAtom } from "../twilio";
import { ChatDrawerWrapper as ChatDrawer } from "./chat";
import { AllClientsChatDrawerWrapper as AllClientsChatDrawer } from "./chat/AllClientsChatDrawer";
import { ClientInformationDrawerWrapper as ClientInformationDrawer } from "./client-information/ClientInformationDrawer";
import { ClientInformationFormResponseDrawerWrapper as ClientInformationFormResponseDrawer } from "./client-information/ClientInformationFormResponseDrawer";
import { ClientInformationProgressNotesDrawerWrapper as ClientInformationNotesDrawer } from "./client-information/ClientInformationProgressNotesDrawer";
import { DrawerState, drawerStateAtom } from "./state";
import { tabMap } from "./settingsTabMap";
import { PendingFormsDrawer } from "./client-information/pending-forms/PendingFormsDrawer";
import { PendingFormsCompletedDrawer } from "./client-information/pending-forms/PendingFormsCompletedDrawer";
import { EditAppointmentDrawerWrapper as EditAppointmentDrawer } from "./schedule-appointment/EditAppointmentDrawer";
import { AddAppointmentDrawerWrapper as AddAppointmentDrawer } from "./schedule-appointment/add-appointment-drawer/AddAppointmentDrawer";
import { PendingMeasuresDrawerWrapper as PendingMeasuresDrawer } from "./measures/PendingMeasuresDrawer";
import { CSSRSFormWrapper as CSSRSForm } from "./measures/CSSRSForm";
import { MeasureResponsesDrawer } from "./measures/MeasureResponsesDrawer";
import { useCallback, useEffect, useRef } from "react";
import { useDrawerButtonRefs } from "../hooks/useDrawerButtonRefs";

function DrawerContent({ drawerState }: { drawerState: DrawerState | null }) {
  switch (drawerState) {
    case DrawerState.SETTINGS:
      return <SettingsDrawer tabMap={tabMap} />;
    case DrawerState.CLIENTS:
      return <ClientsDrawer />;
    case DrawerState.SESSION_CHAT:
    case DrawerState.WAITING_ROOM_CHAT:
    case DrawerState.PARTICIPANT_DISCONNECTED_CHAT:
      return <ChatDrawer />;
    case DrawerState.ALL_CLIENTS_CHAT_LIST:
      return <AllClientsChatDrawer />;
    case DrawerState.CLIENT_INFORMATION:
      return <ClientInformationDrawer />;
    case DrawerState.CLIENT_FORM_RESPONSE:
      return <ClientInformationFormResponseDrawer />;
    case DrawerState.PROGRESS_NOTES:
      return <ClientInformationNotesDrawer />;
    case DrawerState.EDIT_APPOINTMENT:
      return <EditAppointmentDrawer />;
    case DrawerState.PENDING_FORMS:
      return <PendingFormsDrawer />;
    case DrawerState.PENDING_FORMS_COMPLETED:
      return <PendingFormsCompletedDrawer />;
    case DrawerState.ADD_APPOINTMENT:
      return <AddAppointmentDrawer />;
    case DrawerState.PENDING_MEASURES:
      return <PendingMeasuresDrawer />;
    case DrawerState.MEASURE_RESPONSES:
      return <MeasureResponsesDrawer />;
    case DrawerState.CSSRS_FORM:
      return <CSSRSForm />;
    default:
      return null;
  }
}

export function PushDrawerWrapper({ className }: { className?: string }) {
  const { buttonRefs: openButtonRefs } = useDrawerButtonRefs();
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const [drawerState, setDrawerState] = useAtom(drawerStateAtom);
  const [currentConversationSid, setCurrentConversationSid] = useAtom(
    currentConversationSidAtom,
  );
  const onCloseDrawer = useCallback((): void => {
    setDrawerState(null);
    if (currentConversationSid) {
      setCurrentConversationSid(undefined);
    }
    if (drawerState) {
      // On drawer close, try to refocus the button that would normally open
      // whatever `drawerState` was active at the time of closing.
      openButtonRefs.current?.get(drawerState)?.current?.focus();
    }
  }, [
    currentConversationSid,
    drawerState,
    openButtonRefs,
    setCurrentConversationSid,
    setDrawerState,
  ]);

  // On drawer state change, refocus the "close" button
  useEffect(() => {
    if (drawerState) {
      closeButtonRef.current?.focus();
    }
  }, [drawerState]);

  return (
    <PushDrawer
      isOpen={!!drawerState}
      onClose={onCloseDrawer}
      className={className}
      closeButtonRef={closeButtonRef}
    >
      <DrawerContent drawerState={drawerState} />
    </PushDrawer>
  );
}
