import { UserType } from "../types";

// These are our event names
export enum SegmentTrackMetricEnum {
  USER_VIEWED = "User Viewed",
  USER_CLICKED = "User Clicked",
  FORM_SUBMITTED = "Form Submitted",
}

// These are our event types
export enum EventTypeEnum {
  CLIENT_INFO = "Client Info",
  CLIENT_MOOD_CHECKIN = "Client Mood Check-in",
  CLIENT_MOOD_CHECKIN_SUBMITTED = "Client Mood Check-in Submitted",
  CLIENT_WAITING_ROOM = "Telehealth Client Waiting Room",
  PROVIDER_WAITING_ROOM = "Telehealth Provider Waiting Room",
  WAITING_ROOM_SCHEDULE = "Waiting Room Schedule",
  WAITING_ROOM_APPOINTMENT_UPDATED = "Waiting Room Appointment Updated",
  WAITING_ROOM_APPOINTMENT_CREATED = "Waiting Room Appointment Created",
  MEASURE_PROGRESS_CLICKED = "Measure Progress Clicked",
  MEASURE_PROGRESS_VIEWED = "Measure Progress Viewed",
  MEASURE_VIEWED = "Telehealth Measure Viewed",
  CLICK_RECORDING_TOGGLE = "Recording toggle clicked",
  GAVE_TRANSCRIPTION_CONSENT = "User Consented to Transcription",
  PROVIDER_NUDGE_CLICKED = "Provider Nudge Clicked",
}

export type TrackParams = {
  patientShortId: string;
  providerShortId: string;
  numberOfAppointments: number;
  scheduleError: boolean;
  appointmentPosition: number;
  appointmentStatus: "RESCHEDULED" | "CANCELLED";
  appointmentShortId: string;
  appointmentTimeStart: string;
  newRecordingStatus: "PLAY" | "PAUSE";
  entityType: UserType;
  entityShortId: string;
  type: string;
  measureProgressView: "table" | "graph";
  inSession: boolean;
  nudgeId: number;
};

export type ClientInfoParams = Pick<TrackParams, "patientShortId">;
export type WaitingRoomAppointmentUpdatedParams = Pick<
  TrackParams,
  "patientShortId" | "appointmentShortId" | "appointmentStatus"
>;

export type WaitingRoomAppointmentCreatedParams = Pick<
  TrackParams,
  "patientShortId" | "appointmentShortId"
>;

export type MoodCheckinFormViewedParams = Pick<TrackParams, "patientShortId">;
export type MoodCheckinFormSubmittedParams = Pick<
  TrackParams,
  "patientShortId"
>;

export type WaitingRoomScheduleViewParams = Pick<
  TrackParams,
  "numberOfAppointments" | "scheduleError"
>;
export type WaitingRoomScheduleClickParams = Pick<
  TrackParams,
  "appointmentPosition" | "appointmentShortId"
>;
export type WaitingRoomViewParams = Pick<
  TrackParams,
  "appointmentShortId" | "appointmentTimeStart"
>;

export type MeasureViewedParams = Pick<TrackParams, "type">;

export type RecordingToggleClickedParams = Pick<
  TrackParams,
  "appointmentShortId" | "newRecordingStatus" | "entityType"
>;

export type TranscriptionConsentClickedParams = Pick<
  TrackParams,
  "appointmentShortId" | "entityType"
>;

export type MeasureProgressClickedParams = Pick<
  TrackParams,
  "measureProgressView" | "inSession"
>;

export type MeasureProgressViewedParams = Pick<
  TrackParams,
  "measureProgressView" | "inSession"
>;

export type ProviderNudgeClickedParams = Pick<TrackParams, "nudgeId">;

export type UserViewedParams<P extends EventTypeEnum> = {
  componentName?: string;
  componentType?: string;
} & P extends EventTypeEnum.CLIENT_INFO
  ? ClientInfoParams
  : P extends EventTypeEnum.CLIENT_MOOD_CHECKIN
    ? MoodCheckinFormViewedParams
    : P extends EventTypeEnum.WAITING_ROOM_SCHEDULE
      ? WaitingRoomScheduleViewParams
      : P extends EventTypeEnum.CLIENT_WAITING_ROOM
        ? WaitingRoomViewParams
        : P extends EventTypeEnum.PROVIDER_WAITING_ROOM
          ? WaitingRoomViewParams
          : P extends EventTypeEnum.MEASURE_VIEWED
            ? MeasureViewedParams
            : P extends EventTypeEnum.MEASURE_PROGRESS_VIEWED
              ? MeasureProgressViewedParams
              : never;

export type UserClickedParams<P extends EventTypeEnum> =
  P extends EventTypeEnum.WAITING_ROOM_SCHEDULE
    ? WaitingRoomScheduleClickParams
    : P extends EventTypeEnum.CLIENT_INFO
      ? ClientInfoParams
      : P extends EventTypeEnum.CLICK_RECORDING_TOGGLE
        ? RecordingToggleClickedParams
        : P extends EventTypeEnum.GAVE_TRANSCRIPTION_CONSENT
          ? TranscriptionConsentClickedParams
          : P extends EventTypeEnum.MEASURE_PROGRESS_CLICKED
            ? MeasureProgressClickedParams
            : P extends EventTypeEnum.PROVIDER_NUDGE_CLICKED
              ? ProviderNudgeClickedParams
              : never;

export type FormSubmittedParams<P extends EventTypeEnum> =
  P extends EventTypeEnum.CLIENT_MOOD_CHECKIN_SUBMITTED
    ? MoodCheckinFormSubmittedParams
    : P extends EventTypeEnum.WAITING_ROOM_APPOINTMENT_UPDATED
      ? WaitingRoomAppointmentUpdatedParams
      : P extends EventTypeEnum.WAITING_ROOM_APPOINTMENT_CREATED
        ? WaitingRoomAppointmentCreatedParams
        : never;
