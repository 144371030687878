import { MICTypes } from "@grow-therapy-team/sprout-ui";

export type BundleCompletionState = {
  state: "completed" | "partially_completed" | "incomplete";
  lastCompletion: Date | null;
};

export function sortMeasureBundles(
  measureBundles: MICTypes.MeasureBundle[],
  dateField: keyof MICTypes.MeasureBundle = "sentAt",
) {
  return [...measureBundles].sort(
    (a: MICTypes.MeasureBundle, b: MICTypes.MeasureBundle) => {
      return (
        new Date(b[dateField] || 0).getTime() -
        new Date(a[dateField] || 0).getTime()
      );
    },
  );
}

export function getBundleCompletionState(
  bundles: MICTypes.MeasureBundle[],
): BundleCompletionState {
  if (!bundles || bundles.length === 0) {
    return { state: "incomplete", lastCompletion: null };
  }

  const mostRecentBundle = bundles[0]!;
  if (mostRecentBundle.completedAt) {
    return {
      state: "completed",
      lastCompletion: new Date(mostRecentBundle.completedAt),
    };
  }

  const completedBundleItem = mostRecentBundle.measureBundleItems.find(
    (item) => item?.measureResponse?.completedAt,
  );
  if (completedBundleItem) {
    return {
      state: "partially_completed",
      lastCompletion: new Date(
        completedBundleItem.measureResponse!.completedAt!,
      ),
    };
  }

  const lastCompletion = bundles.find(
    (bundle) => bundle?.completedAt,
  )?.completedAt;
  return {
    state: "incomplete",
    lastCompletion: lastCompletion ? new Date(lastCompletion) : null,
  };
}
