import {
  FormListItem,
  Icon,
  Tag,
  TagUse,
  Text,
  MICMeasureBundleScores,
  MeasureBundleObject,
} from "@grow-therapy-team/sprout-ui";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import {
  DrawerState,
  drawerStateAtom,
  isInSessionWithSelectedClientAtom,
  selectedClientMeasuresData,
} from "../state";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { GroupedFormData } from "../../components/forms/types";
import { groupedFormsByTitleForSelectedClientAtom } from "../client-information/state";
import { useOpenClientFormsCallback } from "../client-information/useOpenClientFormsCallback";
import { cssrsMeasuresGate } from "../../statsig/gates";
import { useGate } from "statsig-react";
import { getBundleCompletionState } from "./utils";

function Action() {
  return <Icon aria-hidden icon={faChevronRight} className="mr-2" />;
}

export function MeasuresFormListItem({
  bundles,
  shouldAllowCompletion,
}: {
  bundles: MeasureBundleObject[];
  shouldAllowCompletion: boolean;
}) {
  const { value: cssrsMeasureEnabled } = useGate(cssrsMeasuresGate);
  const { state, lastCompletion } = getBundleCompletionState(bundles);
  const formattedLastCompletion = lastCompletion
    ? lastCompletion.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : null;

  const setDrawerState = useSetAtom(drawerStateAtom);
  const props = useMemo(() => {
    switch (state) {
      case "completed":
        return {
          title: "Measures",
          description: (
            <>
              <Text variant="sm" as="span" className="text-neutral-700">
                Completed {formattedLastCompletion}
              </Text>
              <MICMeasureBundleScores
                cssrsMeasureEnabled={cssrsMeasureEnabled}
                measureBundleItems={bundles[0]!.measureBundleItems}
              />
            </>
          ),
          onClick: () => setDrawerState(DrawerState.MEASURE_RESPONSES),
        };
      case "partially_completed":
        return {
          title: (
            <div className="flex gap-x-2 items-center">
              <Text>Measures</Text>
              <Tag
                aria-label="Client measure is incomplete"
                use={TagUse.Yellow}
              >
                INCOMPLETE
              </Tag>
            </div>
          ),
          description: (
            <>
              <Text variant="sm" as="span" className="text-neutral-700">
                Partially completed {formattedLastCompletion}
              </Text>
              <MICMeasureBundleScores
                cssrsMeasureEnabled={cssrsMeasureEnabled}
                measureBundleItems={bundles[0]!.measureBundleItems}
              />
            </>
          ),
        };
      default:
        return {
          title: (
            <div className="flex gap-x-2 items-center">
              <Text>Measures</Text>
              <Tag
                aria-label="Client measure is incomplete"
                use={TagUse.Yellow}
              >
                INCOMPLETE
              </Tag>
            </div>
          ),
          description: formattedLastCompletion && (
            <Text variant="sm" as="span" className="text-neutral-700">
              Last completed {formattedLastCompletion}
            </Text>
          ),
        };
    }
  }, [
    state,
    formattedLastCompletion,
    bundles,
    setDrawerState,
    cssrsMeasureEnabled,
  ]);

  if (state !== "completed" && !shouldAllowCompletion) return;

  return (
    <FormListItem
      onClick={() => setDrawerState(DrawerState.PENDING_MEASURES)}
      action={<Action />}
      {...props}
    />
  );
}

export function MeasuresFormListItemWrapper() {
  const measureBundles = useAtomValue(selectedClientMeasuresData);
  const shouldAllowCompletion = useAtomValue(isInSessionWithSelectedClientAtom);

  return (
    <MeasuresFormListItem
      bundles={measureBundles}
      shouldAllowCompletion={shouldAllowCompletion}
    />
  );
}

export function SafetyPlanFormListItem({
  form,
  onOpenCompletedForm,
  onOpenIncompleteForm,
  shouldAllowCompletion,
}: {
  form: GroupedFormData;
  onOpenCompletedForm: (formTitle: string) => void;
  onOpenIncompleteForm: () => void;
  shouldAllowCompletion: boolean;
}) {
  const complete = !!form?.formResponseId;
  const props = useMemo(() => {
    if (complete) {
      const submitted = new Date(form.createdAt).toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      return {
        title: form.formTitle,
        description: `Submitted ${submitted}`,
        onClick: () => onOpenCompletedForm(form.formTitle),
      };
    }
    return {
      title: (
        <div className="flex gap-x-2 items-center">
          <Text>{form.formTitle}</Text>
          <Tag aria-label="Client form is incomplete" use={TagUse.Yellow}>
            INCOMPLETE
          </Tag>
        </div>
      ),
      description: null,
      onClick: () => onOpenIncompleteForm(),
    };
  }, [complete, form, onOpenCompletedForm, onOpenIncompleteForm]);

  if (!complete && !shouldAllowCompletion) return;

  return <FormListItem action={<Action />} {...props} />;
}

export function SafetyPlanFormListItemWrapper() {
  const setDrawerState = useSetAtom(drawerStateAtom);
  const onOpenCompletedForm = useOpenClientFormsCallback();
  const shouldAllowCompletion = useAtomValue(isInSessionWithSelectedClientAtom);
  const onOpenIncompleteForm = () => setDrawerState(DrawerState.PENDING_FORMS);
  const forms = useAtomValue(groupedFormsByTitleForSelectedClientAtom);
  const form = forms?.["Safety Plan"]?.[0];

  return (
    form && (
      <SafetyPlanFormListItem
        form={form}
        onOpenCompletedForm={onOpenCompletedForm}
        onOpenIncompleteForm={onOpenIncompleteForm}
        shouldAllowCompletion={shouldAllowCompletion}
      />
    )
  );
}

const THERAPY_REASONS_QUESTION = "therapy_reasons";

/** Intake forms can only be viewed. They cannot be filled out within Telehealth. */
export function IntakeFormListItem({
  form,
  onOpenForm,
}: {
  form: GroupedFormData;
  onOpenForm: (formTitle: string) => void;
}) {
  if (!form?.formResponseId) return null;

  const submitted = new Date(form.createdAt).toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  const seekingHelpFor = form.formAnswers[0].elements.find(
    (el) => el.name == THERAPY_REASONS_QUESTION,
  )?.value;
  const description = (
    <>
      <Text variant="sm">Submitted {submitted}</Text>
      {seekingHelpFor && (
        <Text variant="sm" className="italic">
          Seeking help for: {seekingHelpFor}
        </Text>
      )}
    </>
  );
  return (
    <FormListItem
      title={form.formTitle}
      description={description}
      onClick={() => onOpenForm(form.formTitle)}
      action={<Action />}
    />
  );
}

export function IntakeFormListItemWrapper() {
  const onOpenForm = useOpenClientFormsCallback();
  const forms = useAtomValue(groupedFormsByTitleForSelectedClientAtom);
  const form = forms?.["Intake Form"]?.[0];

  return form && <IntakeFormListItem form={form} onOpenForm={onOpenForm} />;
}
