import { Text, Card, Icon, NudgeObject } from "@grow-therapy-team/sprout-ui";
import { ClientInformationSection } from "./ClientInformationSection";
import { ClientInformationFormsAndQuestionnaires } from "./ClientInformationFormsAndQuestionnaires";
import { CombinedFormDataEntriesByFormTitle, NoteTypeEnum } from "./types";
import { ClientInformationNotes } from "./ClientInformationNotes";
import {
  CLIENT_HAS_LEFT_MESSAGE,
  CLIENT_INFO_ERROR_MESSAGE,
} from "./constants";
import { useGetPatientByShortId } from "../useGetPatientByShortId";
import { useAtomValue } from "jotai";
import { scheduledPatientInformationAtom } from "../schedule-preview/state";
import { AppointmentType } from "../schedule-preview/types";
import {
  completedFormsForSelectedClientAtom,
  hasNewFormsAtomFamily,
} from "./state";
import { isInSessionWithSelectedClientAtom } from "../state";
import { faSpinnerThird as fadSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { ClientInformationProgressWrapper as ClientInformationProgress } from "./ClientInformationProgress";
import { useGate } from "statsig-react";
import { micNudgesGate } from "../../statsig/gates";
import { ClinicalConsiderationsSectionWrapper as ClinicalConsiderationsSection } from "./ClinicalConsiderationsSection";

export type ClientInformationDrawerBodyProps = {
  isError?: boolean;
  isLoading: boolean;
  isEmpty: boolean;
  clientInformation: {
    appointmentType: AppointmentType;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    preferredShortName: string | null;
    primaryInsuranceCoverage: string | null;
    preferredFullName: string | null;
    pronouns: string | null;
    telephone: string | null;
    gender: string | null;
    readableDob: string | null;
    formattedAddress: string | null;
    isSelfPay: boolean;
    nudges: NudgeObject[];
    patientId: string;
  };
  noteType?: NoteTypeEnum;
  completedForms?: CombinedFormDataEntriesByFormTitle;
  hasNewForms?: boolean;
};

export function ClientInformationTab({
  isEmpty,
  isError,
  isLoading,
  clientInformation,
  noteType,
  completedForms,
  hasNewForms,
}: ClientInformationDrawerBodyProps) {
  const hasCompletedForms =
    completedForms && Object.values(completedForms).length > 0;
  const isInSessionWithSelectedClient = useAtomValue(
    isInSessionWithSelectedClientAtom,
  );
  const shouldShowFormsSection =
    isInSessionWithSelectedClient || hasCompletedForms;

  const { value: micNudgesGateEnabled } = useGate(micNudgesGate);
  const shouldShowClinicalConsiderations =
    micNudgesGateEnabled && !!clientInformation.nudges.length;

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center">
          <Icon
            aria-hidden={false}
            aria-label="Loading..."
            size="xl"
            className="text-lilac-700 fa-spin"
            icon={fadSpinnerThird}
          />
        </div>
      ) : isError ? (
        <Card
          data-testid="client-information.body.error"
          className="flex flex-row items-center"
        >
          <Icon aria-hidden icon={faWarning} className="mr-2" color="#CC6F52" />
          <Text variant="xs" className="text-left fs-exclude">
            {CLIENT_INFO_ERROR_MESSAGE}
          </Text>
        </Card>
      ) : isEmpty ? (
        <div className="flex flex-col h-full ">
          <div className="pt-6 mt-auto">
            <Text variant="xs" className="text-neutral-700 text-center">
              {CLIENT_HAS_LEFT_MESSAGE}
            </Text>
          </div>
        </div>
      ) : (
        <>
          {shouldShowClinicalConsiderations && (
            <ClinicalConsiderationsSection
              nudges={clientInformation.nudges}
              patientId={clientInformation.patientId}
            />
          )}
          <ClientInformationSection
            data-testid="client-information.body.content"
            clientInformation={clientInformation}
          />
          {noteType === NoteTypeEnum.GROW_PROGRESS && (
            <ClientInformationNotes hasBottomBorder={hasCompletedForms} />
          )}
          <ClientInformationProgress />
          {shouldShowFormsSection && (
            <ClientInformationFormsAndQuestionnaires
              completedForms={completedForms}
              hasNewForms={hasNewForms}
            />
          )}
        </>
      )}
    </>
  );
}

export function ClientInformationTabWrapper({
  isEmpty,
  selectedPatientShortId,
}: {
  isEmpty: boolean;
  selectedPatientShortId: string | undefined;
}) {
  const {
    loading: isLoading,
    data: patientChartData,
    error,
  } = useGetPatientByShortId(selectedPatientShortId);
  const completedForms = useAtomValue(completedFormsForSelectedClientAtom);
  const scheduledPatientInformation = useAtomValue(
    scheduledPatientInformationAtom,
  );
  const notes = patientChartData?.patientChart?.lastAppointment?.notes;
  const noteType: NoteTypeEnum = notes?.[0]?.noteType ?? ("" as NoteTypeEnum);

  const getAppointmentType = () => {
    if (
      scheduledPatientInformation?.appointmentType ===
      AppointmentType.Consultation
    ) {
      return AppointmentType.Consultation;
    } else if (patientChartData?.patientChart?.lastAppointment?.id) {
      return AppointmentType.FollowUp;
    } else {
      return AppointmentType.Intake;
    }
  };

  const clientInformation = {
    appointmentType: getAppointmentType(),
    firstName: patientChartData?.patientChart?.firstName ?? "",
    lastName: patientChartData?.patientChart?.lastName ?? "",
    email: patientChartData?.patientChart?.email ?? "",
    preferredShortName:
      patientChartData?.patientChart?.preferredShortName ?? "",
    primaryInsuranceCoverage:
      patientChartData?.patientChart?.primaryInsuranceCoverage
        ?.payorClientBookingName?.name ?? "",
    preferredFullName: patientChartData?.patientChart?.preferredFullName ?? "",
    pronouns: patientChartData?.patientChart?.pronouns ?? "",
    telephone: patientChartData?.patientChart?.telephone ?? "",
    gender: patientChartData?.patientChart?.gender ?? "",
    readableDob: patientChartData?.patientChart?.readableDob ?? "",
    formattedAddress: patientChartData?.patientChart?.formattedAddress ?? "",
    isSelfPay: patientChartData?.patientChart?.isSelfPay ?? false,
    patientId: patientChartData?.patientChart?.id ?? "",
    nudges: patientChartData?.patientChart?.nudges ?? [],
  };
  const hasNewForms = useAtomValue(
    hasNewFormsAtomFamily(selectedPatientShortId ?? ""),
  );

  return (
    <ClientInformationTab
      isEmpty={isEmpty}
      isLoading={isLoading}
      isError={!!error}
      clientInformation={clientInformation}
      noteType={noteType}
      completedForms={completedForms}
      hasNewForms={hasNewForms}
    />
  );
}
