import { faHexagonExclamation } from "@fortawesome/pro-solid-svg-icons";
import { Alert, Button } from "@grow-therapy-team/sprout-ui";
import { twMerge } from "tailwind-merge";

type CSSRSPromptToastProps = {
  onClose: () => void;
  patientName: string;
  onAdministerCSSRS: () => void;
  className?: string;
};

const CSSRPromptText = {
  HEADING: "Consider administering the C-SSRS",
  BODY: "screened positive for suicidal thinking (question 9 on PHQ9). As clinical best practice, consider further evaluation using the Columbia-Suicide Severity Rating Scale (C-SSRS).",
  CALL_TO_ACTION: "Administer C-SSRS",
};

export function CSSRSPromptAlert({
  onClose,
  patientName,
  onAdministerCSSRS,
  className,
}: CSSRSPromptToastProps) {
  return (
    <Alert
      actions={
        <Button
          className="whitespace-nowrap"
          use="secondary"
          onClick={onAdministerCSSRS}
        >
          {CSSRPromptText.CALL_TO_ACTION}
        </Button>
      }
      className={twMerge("mb-4", className)}
      heading={CSSRPromptText.HEADING}
      icon={faHexagonExclamation}
      use="error"
      onClose={onClose}
    >
      <span className="fs-exclude data-dd-privacy">
        {`${patientName || "Your client"}`}
      </span>{" "}
      {`${CSSRPromptText.BODY}`}
    </Alert>
  );
}
