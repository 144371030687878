import { Text, Icon, Button, ButtonSize } from "@grow-therapy-team/sprout-ui";
import { ReactNode, useMemo } from "react";
import { HelpfulTipsDrawerStates, VisitorDrawerState } from "../state";
import { TrackingEvents, sendLoggingEvents } from "../../events";
import {
  faChevronRight,
  faHourglassEnd,
  faHourglassStart,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";

export type DrawerButton = {
  text: string | ReactNode;
  icon: IconDefinition;
  onClick: () => void;
  testId?: string;
};

export function HelpfulTipsDrawerButtonMenu({
  openTipsDrawer,
}: {
  openTipsDrawer: (state: HelpfulTipsDrawerStates) => void;
}) {
  const drawerButtons: DrawerButton[] = useMemo(
    () => [
      {
        text: <>Tips for before your session</>,
        icon: faHourglassStart,
        testId: "drawer-button-menu.before-session",

        onClick: () =>
          openTipsDrawer(VisitorDrawerState.HELPFUL_TIPS_BEFORE_SESSION),
      },
      {
        text: <>Tips for after your session</>,
        icon: faHourglassEnd,
        testId: "drawer-button-menu.after-session",

        onClick: () =>
          openTipsDrawer(VisitorDrawerState.HELPFUL_TIPS_AFTER_SESSION),
      },
    ],
    [openTipsDrawer],
  );

  return (
    <ul className="flex flex-col gap-4">
      {drawerButtons.map(({ text, icon, onClick, testId }) => (
        <li key={testId}>
          <Button
            data-testid={testId}
            onClick={() => {
              sendLoggingEvents(TrackingEvents.CLIENT_PRE_SESSION_TIP_OPEN);
              onClick();
            }}
            use="secondary"
            className="rounded-lg p-4 sm:py-6 sm:px-8 shadow-border-neutral-300 border-0 hover:shadow-border-lilac-700 hover:bg-lilac-100 hover:border-lilac-700 flex gap-4 w-full justify-between align-middle items-center"
            size={ButtonSize.Large}
          >
            <Icon icon={icon} className="fa-fw text-lilac-600" />
            <Text className="text-neutral-900 font-medium relative flex-1 text-left">
              {text}
            </Text>
            <Icon
              aria-hidden
              icon={faChevronRight}
              className="fa-fw text-lilac-700"
              size="sm"
            />
          </Button>
        </li>
      ))}
    </ul>
  );
}
