import { useCallback, useMemo } from "react";
import { MeasureSchemaAndAnswers } from "../../components/measures/types";
import { PageComponentProps } from "../../components";
import {
  toast,
  SubmitMeasureResponseCallbackArgs as BaseSubmitMeasureResponseCallbackArgs,
  useSubmitMeasureResponseCallback as useBaseSubmitMeasureResponseCallback,
  MeasureValidationError,
} from "@grow-therapy-team/sprout-ui";
import { logger } from "../../datadog";
import { CallbackFailure, CallbackSuccess } from "../../types";
import { GET_PATIENT_BY_PATIENT_SHORT_ID_GQL } from "../../provider/useGetPatientByShortId";

type SubmitMeasureResponseResult =
  | CallbackSuccess<string, "SUBMITTED">
  | CallbackFailure<Error, "FAILED_TO_SUBMIT" | "MISSING_REQUIRED_DATA">;

type SubmitMeasureResponseCallbackArgs = Pick<
  PageComponentProps<MeasureSchemaAndAnswers>,
  "page"
> &
  Partial<
    Pick<
      BaseSubmitMeasureResponseCallbackArgs,
      "clientUserShortId" | "providerShortId"
    >
  > &
  Pick<BaseSubmitMeasureResponseCallbackArgs, "completionCampaign">;

type SubmitMeasureResponseCallback = (
  args: SubmitMeasureResponseCallbackArgs,
) => Promise<SubmitMeasureResponseResult>;

export function useSubmitMeasureResponseCallback(): [
  SubmitMeasureResponseCallback,
  { isLoading: boolean },
] {
  const startedAt = useMemo(() => new Date().toISOString(), []);

  const [submitMeasureResponse, requestData] =
    useBaseSubmitMeasureResponseCallback();

  const handleSubmission = useCallback(
    async ({
      page,
      clientUserShortId,
      providerShortId,
      completionCampaign,
    }: SubmitMeasureResponseCallbackArgs): Promise<SubmitMeasureResponseResult> => {
      const { schema, hasSubmitted } = page;
      if (
        !clientUserShortId ||
        !schema ||
        hasSubmitted ||
        (schema.isProviderSpecific && !providerShortId)
      ) {
        return {
          status: "failure",
          code: "MISSING_REQUIRED_DATA",
          value: Error("Missing required data"),
        };
      }

      try {
        const { data } = await submitMeasureResponse({
          clientUserShortId,
          providerShortId,
          completionCampaign,
          startedAt,
          schema,
          shouldShowErrorToast: false,
          apolloOptions: {
            context: {
              skipAuth: true,
            },
            refetchQueries: [GET_PATIENT_BY_PATIENT_SHORT_ID_GQL],
          },
        });
        return {
          status: "success",
          code: "SUBMITTED",
          value:
            data?.submitMeasureResponse?.measureResponse
              ?.measureResponseShortId ?? "",
        };
      } catch (err) {
        if (!(err instanceof MeasureValidationError)) {
          logger.error(
            "Could not submit measure",
            { clientUserShortId },
            err as Error,
          );
          toast.error("Unable to submit form due to an expected error.");
        }
        return {
          status: "failure",
          code: "FAILED_TO_SUBMIT",
          value: err as Error,
        };
      }
    },
    [startedAt, submitMeasureResponse],
  );

  return [handleSubmission, requestData];
}
